import * as React from "react";
import { SpinButton } from "@fluentui/react-components";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import { Row } from "../../../components/Layout/Space";
initializeIcons(undefined, { disableWarnings: true });
import "../Viz.scss";

interface SpinButtonProps {
  label?: string;
  labelWidth: number;
  value: any;
  suffix?: string;
  min?: number;
  max?: number;
  step?: number;
  isDisabled?: boolean;
  dataTestID?: string;
  onChange: (event, data) => void;
}

type FormatterFn = (value: number) => string;
export const Spinner: React.FC<SpinButtonProps> = ({
  value,
  label,
  labelWidth,
  onChange,
  suffix,
  max,
  min = 0,
  step,
  isDisabled,
  dataTestID,
}) => {
  const formatter: FormatterFn = (value) => (suffix ? `${value}${suffix}` : `${value}`);

  const onSpinButtonChange = (_, data) => {
    if (data.value !== undefined && data.value !== null) {
      onChange(_, formatter(data.value));
    }
  };

  return (
    <div style={{ padding: "5px 0 5px 0" }}>
      <Row justifyContent="space-between">
        <label className="label" style={{ minWidth: labelWidth }}>
          {label}
        </label>
        <SpinButton
          readOnly
          value={value}
          data-testid={dataTestID}
          max={max}
          min={min}
          step={step}
          style={{ width: "100%" }}
          incrementButton={{ disabled: isDisabled }}
          decrementButton={{ disabled: isDisabled }}
          displayValue={formatter(value)}
          onChange={onSpinButtonChange}
        />
      </Row>
    </div>
  );
};
