import { combineMode, convertUserCodeToGridCode } from "xlcommon/src/pyscript/utils";
import {
  runExcelSafeContext,
  splitFullAddress,
  worksheetIdFromSheetName,
  writeToCell,
  isPythonEnabled,
} from "xlcommon/src/excel/excel-grid-utils";
import { PyScriptLinkage, PyScriptOutput } from "xlcommon/src/pyscript/types";
import { PYTHON_SETTINGS_DEFINITION, PythonExecutionLocation, PythonSettings } from "./types";
import Env from "xlcommon/src/environ";

async function writePyScriptCode(worksheetId: string, addr: string, interp: string, userCode: string, mode: number) {
  await runExcelSafeContext(async (context) => {
    const sheet = context.workbook.worksheets.getItem(worksheetId);
    const rng = sheet.getRange(addr);
    rng.formulas = [[convertUserCodeToGridCode(interp, userCode, mode, [])]];
    await context.sync();
  });
}

export async function writeToExecutionLocation(fullAddress: string, data: any) {
  const executionLocation = await getPythonLocation();
  if (executionLocation === PythonExecutionLocation.Azure) {
    return await writeToCell(fullAddress, data);
  } else if (executionLocation === PythonExecutionLocation.Local) {
    // Choose isolated mode since code does not depend on previously defined local variables.
    const mode = combineMode({ output: PyScriptOutput.Excel, linkage: PyScriptLinkage.Isolated });
    const [sheetName, addr] = splitFullAddress(fullAddress);
    const sheetId = await worksheetIdFromSheetName(sheetName);
    return await writePyScriptCode(sheetId, addr, "py", data, mode);
  }
}

export async function getPythonLocation(): Promise<PythonExecutionLocation> {
  return await runExcelSafeContext(async (context) => {
    const savedSettings = context.workbook.settings.getItemOrNullObject(PYTHON_SETTINGS_DEFINITION);
    savedSettings.load("value");
    await context.sync();

    if (savedSettings.isNullObject) {
      // Check if Python in Excel is enabled in the workbook
      const hasPython = (await isPythonEnabled()) || Env.UNLOCKED;
      if (hasPython) {
        console.log("No settings found - Defaulting to Azure (Python in Excel is available).");
        return PythonExecutionLocation.Azure;
      } else {
        console.log("No settings found - Defaulting to PyScript (Python in Excel is not available).");
        return PythonExecutionLocation.Local;
      }
    } else {
      const savedSettingsObject = JSON.parse(savedSettings.value);
      return savedSettingsObject.executionLocation as PythonExecutionLocation;
    }
  });
}

export async function savePythonSettings({ executionLocation }: PythonSettings) {
  await runExcelSafeContext(async (context) => {
    let serialized = JSON.stringify({ executionLocation });
    context.workbook.settings.add(PYTHON_SETTINGS_DEFINITION, serialized);
    await context.sync();
  });
}

export async function clearBindingDataFromExcel(bindId: string) {
  return await runExcelSafeContext(async (context) => {
    const binding = context.workbook.bindings.getItem(bindId);

    // Get the range associated with the binding
    const range = binding.getRange();

    // Clear the contents of the range / cell
    range.clear(Excel.ClearApplyTo.contents);

    await context.sync();
  });
}
