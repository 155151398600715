import { BaseDesign, BaseSetup } from "./PlotTypes";
import { ValueTracker } from "../../pages/Visualization/MVCShared/types";
import styles from "../../styles.module.scss";

export interface DisplotSetup extends BaseSetup {
  // TODO: specifics
  kind: ValueTracker<string>;
  row: string;
  col: string;
  isCollapsed: boolean;
}

export interface DisplotDesign extends BaseDesign {
  // TODO: specifics
  height: ValueTracker<number>;
  aspect: ValueTracker<number>;
  legend: ValueTracker<boolean>;
  colWrap: ValueTracker<number>;
  isCollapsed: boolean;
  legendPosition: string;
  topPosition: ValueTracker<number>;
  rightPosition: ValueTracker<number>;
  yticks: ValueTracker<number>;
  xticks: ValueTracker<number>;
}

export const displotSetup: DisplotSetup = {
  headers: [],
  xAxis: "",
  yAxis: "",
  kind: new ValueTracker("Histogram"),
  colorBy: "",
  row: "",
  col: "",
  // Items for facetGrid start
  shareX: new ValueTracker(true),
  shareY: new ValueTracker(true),
  dropna: new ValueTracker(true),
  // end
  isCollapsed: true,
};

export const displotDesign: DisplotDesign = {
  plotTitle: "",
  xAxisLabel: "",
  yAxisLabel: "",
  height: new ValueTracker(5),
  aspect: new ValueTracker(1),
  color: new ValueTracker(styles.seabornDefault),
  palette: new ValueTracker("Tab10"),
  legend: new ValueTracker(true),
  colWrap: new ValueTracker(0),
  // Items for facetGrid start
  xlim: new ValueTracker(0),
  ylim: new ValueTracker(0),
  facetPalette: new ValueTracker("Tab10"),
  // end
  isCollapsed: true,
  legendPosition: "--Select--",
  topPosition: new ValueTracker(0.5),
  rightPosition: new ValueTracker(1),
  yticks: new ValueTracker(0),
  xticks: new ValueTracker(0),
  majorHorizontal: new ValueTracker(false),
  majorVertical: new ValueTracker(false),
  minorHorizontal: new ValueTracker(false),
  minorVertical: new ValueTracker(false),
  leftSpine: true,
  bottomSpine: true,
  topSpine: false,
  rightSpine: false,
  borderCollapsed: true,
  gridlinesCollapsed: true,
};
