import React, { useState, useEffect, Suspense } from "react";
import DOMPurify from "dompurify";
import { Spinner } from "@fluentui/react-components";
import { Row } from "../../components/Layout/Space";
import { getFullAddressFromRangeSelection, getAliasFromRangeSelection } from "xlcommon/src/excel/excel-grid-utils";
import { writeToExecutionLocation, getPythonLocation } from "../../../excel/grid-utils";
import { PythonExecutionLocation } from "../../../excel/types";
import ActionButtons from "./ActionButtons";
import { buildChartPreview } from "./viz-preview";
import Trace from "./Trace";
import { useChart, useChartManager } from "../../../taskpane/hooks/plots/useCentralViz";
import { BaseDesign, BaseSetup } from "../../../taskpane/hooks/plots/PlotTypes";
import { buildPythonInExcelCode } from "./MVCShared/CodeBuilder";

const Preview = () => {
  const [previewLoading, setPreviewLoading] = useState<boolean>();
  const [chartPreview, setChartPreview] = useState<string>();
  const { plotType } = useChartManager();
  const { common, codeFragments, serializeContext } = useChart<BaseSetup, BaseDesign>(plotType);

  if (!common.inputData) {
    return <p>Select input data in the setup tab to preview chart.</p>;
  }

  const isDisabled = !common.outputCell || !common.outputCell.displayName;

  const handleCreateClick = async () => {
    const addr = await getFullAddressFromRangeSelection(common.outputCell);
    if (common.typedCode) {
      await writeToExecutionLocation(addr, common.typedCode);
    } else {
      await writeToExecutionLocation(addr, await buildPythonInExcelCode(codeFragments));
    }
    await serializeContext();
  };

  const [trace, setTrace] = useState<string>();
  useEffect(() => {
    (async () => {
      try {
        setPreviewLoading(true);

        const sourceAddr = await getFullAddressFromRangeSelection(common.inputData);
        if (!sourceAddr) return;
        const aliasAddr = await getAliasFromRangeSelection(common.inputData);

        let code: string = common.typedCode ?? (await buildPythonInExcelCode(codeFragments));

        const executionLocation = await getPythonLocation();
        if (executionLocation === PythonExecutionLocation.Azure) {
          const xlPatt = /xl\("(.+?)",\s*headers=(True|False)\)/;
          const matches = xlPatt.exec(code);
          if (matches) {
            if (matches[1] !== aliasAddr) {
              setTrace("Error: Unable to generate preview if input data source is manually changed in code editor");
              return;
            }
            // Replace xl() with pd.DataFrame()
            const dataFrame = matches[2] === "True" ? "DataFrame(_data[1:], columns=_data[0])" : "DataFrame(_data)";
            code = code.replace(matches[0], dataFrame);
          }
        } else {
          const psPatt = /REF\("(.+?)"\)/;
          const matches = psPatt.exec(code);
          if (matches) {
            if (matches[1] !== aliasAddr) {
              setTrace("Error: Unable to generate preview if input data source is manually changed in code editor");
              return;
            }
            // Replace REF() with _data
            code = code.replace(matches[0], "_data");
          }
        }

        const result = await buildChartPreview(code, sourceAddr);
        if (result.indexOf("<img") !== -1) {
          setChartPreview(result);
        } else {
          setTrace(result);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setPreviewLoading(false);
      }
    })();
  }, []);

  return (
    <Suspense>
      {previewLoading && (
        <Row justifyContent="center" gap={15}>
          <div style={{ margin: "10px 0" }}>
            <Spinner
              size="tiny"
              label={<span style={{ fontWeight: 400 }}>Loading plot preview for selected data range...</span>}
              labelPosition="after"
            />
          </div>
        </Row>
      )}
      {trace && <Trace content={trace} />}
      {chartPreview && (
        <Row>
          <div className="plot-preview" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(chartPreview) }} />
        </Row>
      )}
      <ActionButtons onPrimaryClick={handleCreateClick} disabled={isDisabled} />
    </Suspense>
  );
};

export default Preview;
