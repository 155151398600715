import { getGlobal } from "../global";
import { updateValueType, convertExcelToISO } from "./excel-date-utils";
import { convertGridCodeToUserCode } from "../pyscript/utils";
import Env from "../environ";

export const EXCEL_ROW_MAX = 1048576;
export const EXCEL_COLUMN_MAX = 16348;

const g = getGlobal();

// Sets the calculation mode and returns the previous mode setting
export async function setCalculationMode(mode: Excel.CalculationMode): Promise<string> {
  // Save previous calculation mode
  // This needs to be a separate invocation from setting
  // the calc mode in case of errors which cause it to be
  // run multiple times.
  const prevMode = await runExcelSafeContext(async (context) => {
    context.application.load("calculationMode");
    await context.sync();
    return context.application.calculationMode;
  });
  // Set new mode
  await runExcelSafeContext(async (context) => {
    context.application.calculationMode = mode;
    await context.sync();
  });
  return prevMode;
}

export interface SplitNameAddr {
  sheetName: string;
  addr: string;
}

export interface SplitIdAddr {
  sheetId: string;
  addr: string;
}

export enum RangeType {
  Table = "Table",
  Sheet = "Sheet",
  Named = "Named",
  CellBinding = "CellBinding",
  RangeBinding = "RangeBinding",
  SpillBinding = "SpillBinding",
}

export type RangeSelection = {
  rangeType: RangeType;
  identifier: string;
  displayName?: string;
  bindingAutoDelete?: boolean;
};

export function isBinding(rs: RangeSelection): boolean {
  switch (rs?.rangeType) {
    case RangeType.CellBinding:
    case RangeType.RangeBinding:
    case RangeType.SpillBinding:
      return true;
    default:
      return false;
  }
}

/**
 * Safer replacement than manually calling `await Excel.run(async (context) => { ... });`
 *
 * Handles errors for invalid bindings (automatically deleting them).
 * Handles generic RichAPI.errors and retries the batch.
 * Handles when the user is in cell edit mode.
 *
 * NOTE: We might want to have a tool-wide overlay that tells the user to exit cell edit mode
 *       and somehow link that to every call.
 *
 * @param batch async function taking Excel.RequestContext as input
 * @param bindId optional (for faster removal of invalid binding)
 */
export async function runExcelSafeContext<T>(
  batch: (context: Excel.RequestContext) => Promise<T>,
  {
    bindId,
    retryCount = 25, // 5 seconds of retry
  }: {
    bindId?: string;
    retryCount?: number;
  } = {}
): Promise<T> {
  return await Excel.run({ delayForCellEdit: true }, async (context) => {
    try {
      return await batch(context);
    } catch (err) {
      if (err.code === "InvalidBinding") {
        // At least one binding is invalid
        // We have to manually walk thru each one to find the bad one and delete it
        // https://stackoverflow.com/questions/61801038/getting-rid-of-invalid-bindings-in-a-workbook
        context.workbook.bindings.load("items/id");
        await context.sync();
        if (bindId) {
          context.workbook.bindings.getItemOrNullObject(bindId);
          try {
            await context.sync();
          } catch (err) {
            console.log(`Removing provided bindId as invalid: ${bindId}`);
            await removeBindingById(bindId);
          }
        } else {
          for (const b of context.workbook.bindings.items) {
            b.load("isNullObject");
            try {
              await context.sync();
            } catch (err) {
              // Found an invalid binding
              console.log(`Removing invalid binding: ${b.id}`);
              await removeBindingById(b.id);
            }
          }
        }
        console.error(err);
        return await runExcelSafeContext<T>(batch);
      } else if (err.code === "RichAPI.Error") {
        if (retryCount > 0) {
          // Wait, then try again
          await new Promise((resolve) => setTimeout(resolve, 200));
          console.log("Ran into RichAPI.Error -- trying again ", retryCount);
          return await runExcelSafeContext<T>(batch, { retryCount: retryCount-- });
        } else {
          throw err;
        }
      }
    }
  });
}

function removeBindingById(bindId: string) {
  return new Promise((resolve, reject) => {
    // Use the old API to remove it
    Office.context.document.bindings.releaseByIdAsync(bindId, (result) => {
      if (result.status === Office.AsyncResultStatus.Succeeded) {
        resolve(result.value);
      } else {
        reject(result.error);
      }
    });
  });
}

export async function convertToRangeSelection(name: string): Promise<RangeSelection> {
  return await runExcelSafeContext(async (context) => {
    const worksheet = context.workbook.worksheets.getItemOrNullObject(name);
    const table = context.workbook.tables.getItemOrNullObject(name);
    const binding = context.workbook.bindings.getItemOrNullObject(name);
    await context.sync();

    if (!worksheet.isNullObject) {
      worksheet.load("id");
      await context.sync();
      return {
        rangeType: RangeType.Sheet,
        identifier: worksheet.id,
        displayName: name,
      };
    } else if (!table.isNullObject) {
      table.load("id");
      await context.sync();
      return {
        rangeType: RangeType.Table,
        identifier: table.id,
        displayName: name,
      };
    } else if (!binding.isNullObject) {
      binding.load("id");
      await context.sync();
      return {
        rangeType: RangeType.RangeBinding,
        identifier: binding.id,
        displayName: name,
      };
    }

    throw new Error(`Worksheet, table, or binding with name '${name}' not found.`);
  });
}

export function displayRangeSelection(rs: RangeSelection, includeBrackets = true): string {
  if (!rs || !rs.rangeType) {
    return "";
  }
  const brackets = includeBrackets ? `[${rs.rangeType}] ` : "";
  switch (rs.rangeType) {
    case RangeType.Sheet:
    case RangeType.Table:
      return `${brackets}${rs.displayName}`;
    case RangeType.CellBinding:
    case RangeType.RangeBinding:
    case RangeType.SpillBinding:
      return rs.displayName || "";
    default:
      return `${brackets}${rs.identifier}`;
  }
}

async function getBindingAddress(rs: RangeSelection): Promise<string> {
  if (!isBinding(rs) || !rs.identifier) {
    return "";
  }

  let address = await runExcelSafeContext(async (context) => {
    let b = context.workbook.bindings.getItem(rs.identifier);
    try {
      await context.sync();
    } catch (e) {
      throw Error(`Binding ${rs.identifier} not found in spreadsheet.`);
    }
    let rng = b.getRange();
    rng.load("address");
    await context.sync();
    return rng.address;
  });
  if (rs.rangeType === RangeType.RangeBinding) return address;
  // Trim address to top-left cell
  address = address.split(":")[0];
  if (rs.rangeType === RangeType.CellBinding) return address;
  if (rs.rangeType === RangeType.SpillBinding) return `${address}#`;
  return "";
}

async function findByIdOrDisplayName(
  context: Excel.RequestContext,
  rs: RangeSelection
): Promise<Excel.Table | Excel.Worksheet> {
  let func: (key: string) => Excel.Table | Excel.Worksheet;
  switch (rs.rangeType) {
    case RangeType.Sheet:
      func = (id: string) => context.workbook.worksheets.getItem(id);
      break;
    case RangeType.Table:
      func = (id: string) => context.workbook.tables.getItem(id);
      break;
    default:
      throw Error(`Invalid RangeType for findByIdOrDisplayName: ${rs.rangeType}`);
  }
  let obj = func(rs.identifier);
  try {
    await context.sync();
    return obj;
  } catch (e) {
    // Check if the displayName is found. If it is, we can assume the user deleted
    //   the previous table, then renamed another one with this name, assuming
    //   the link would hold. This is probably a safe assumption more often than not.
    if (rs.displayName) {
      obj = func(rs.displayName);
      try {
        await context.sync();
        return obj;
      } catch (e) {
        throw Error(`${rs.rangeType} ${rs.displayName} not found in spreadsheet.`);
      }
    } else {
      throw Error(`${rs.rangeType} ${rs.identifier} not found in spreadsheet.`);
    }
  }
}

export async function refreshRangeSelection(rs: RangeSelection): Promise<RangeSelection> {
  switch (rs?.rangeType) {
    case RangeType.Table:
      return await runExcelSafeContext(async (context) => {
        let t = await findByIdOrDisplayName(context, rs);
        t.load("id");
        t.load("name");
        await context.sync();
        return { ...rs, identifier: t.id, displayName: t.name };
      });
    case RangeType.Sheet:
      return await runExcelSafeContext(async (context) => {
        let s = await findByIdOrDisplayName(context, rs);
        s.load("id");
        s.load("name");
        await context.sync();
        return { ...rs, identifier: s.id, displayName: s.name };
      });
    case RangeType.CellBinding:
    case RangeType.RangeBinding:
    case RangeType.SpillBinding:
      return { ...rs, displayName: await getBindingAddress(rs) };
    default:
      return rs;
  }
}

// TODO: change return type to SplitNameAddr
/**
 * Takes a full address (ex. Sheet1!A4:B15) and
 * returns separated sheet name and range
 *
 * @param fullAddress
 * @returns [sheetName, rangeAddress]
 */
export function splitFullAddress(fullAddress: string): [string, string] {
  // Javascript -- why no rsplit?!?
  let sheetName = fullAddress.split("!").slice(0, -1).join("!");
  let addr = fullAddress.split("!").slice(-1)[0];

  if (sheetName === undefined || addr === undefined) {
    throw Error(`Unresolvable fullAddress: ${fullAddress}`);
  }

  // Remove any string delimiters (happens for spaces and symbols in the sheet name)
  if (sheetName.startsWith("'")) {
    sheetName = sheetName.slice(1, -1);
  }

  // De-duplicate double-apostrophe
  sheetName = sheetName.replaceAll("''", "'");

  return [sheetName, addr];
}

export function joinFullAddress(sheetName: string, addr: string): string {
  // Escape single quotes
  sheetName = sheetName.replaceAll("'", "''");

  // Always add surrounding single-quotes; Excel will remove them if needed
  return `'${sheetName}'!${addr}`;
}

export async function worksheetIdFromSheetName(sheetName: string): Promise<string> {
  return await runExcelSafeContext(async (context) => {
    const sheet = context.workbook.worksheets.getItem(sheetName);
    sheet.load("id");
    await context.sync();
    return sheet.id;
  });
}

export async function sheetNameFromWorksheetId(worksheetId: string): Promise<string> {
  return await runExcelSafeContext(async (context) => {
    const sheet = context.workbook.worksheets.getItem(worksheetId);
    sheet.load("name");
    await context.sync();
    return sheet.name;
  });
}

// TODO: Revisit the linting here
/* eslint-disable office-addins/call-sync-before-read */
export async function readFromRange(fullAddress: string, numRows = -1, numColumns = -1): Promise<any[][]> {
  let [sheetName, addr] = splitFullAddress(fullAddress);

  let result: string[][] = [];
  await runExcelSafeContext(async (context) => {
    let sheet = context.workbook.worksheets.getItem(sheetName);
    let rng = sheet.getRange(addr);
    if (numRows > 0 || numColumns > 0) {
      rng.load("rowIndex");
      rng.load("columnIndex");
      rng.load("rowCount");
      rng.load("columnCount");
      await context.sync();

      // Set the of rows and columns selected, or default to the min number of rows for optimization
      const rowCount = numRows > 0 ? Math.min(numRows, rng.rowCount) : rng.rowCount;
      const colCount = numColumns > 0 ? Math.min(numColumns, rng.columnCount) : rng.columnCount;
      rng = sheet.getRangeByIndexes(rng.rowIndex, rng.columnIndex, rowCount, colCount);
    }
    rng.load("values");
    await context.sync();
    result = rng.values;
  });
  return result;
}

export async function getValueTypesOfRange(fullAddress: string, colLimit: number = -1): Promise<string[]> {
  // Returns a list of value types for each column
  // Valid value types are: "String", "Double", "Boolean", "Date", "Time", "Datetime"
  const [sheetName, addr] = splitFullAddress(fullAddress);

  let result: string[] = [];
  await runExcelSafeContext(async (context) => {
    const sheet = context.workbook.worksheets.getItem(sheetName);
    const rng = sheet.getRange(addr);
    rng.load("rowIndex");
    rng.load("rowCount");
    rng.load("columnIndex");
    rng.load("columnCount");
    await context.sync();
    // Inspect the format of the 2nd row (ignores formatting of header)
    if (colLimit === -1) colLimit = rng.columnCount;
    const rng2 = sheet.getRangeByIndexes(
      rng.rowIndex,
      rng.columnIndex,
      Math.min(rng.rowCount, 2),
      Math.min(rng.columnCount, colLimit)
    );
    rng2.load("valueTypes");
    rng2.load("numberFormat");
    rng2.load("numberFormatCategories");
    await context.sync();
    const formats = rng2.numberFormat.slice(-1)[0];
    const categories = rng2.numberFormatCategories.slice(-1)[0];
    result = rng2.valueTypes.slice(-1)[0].map((x, i) => updateValueType(x, categories[i], formats[i]));
  });
  return result;
}

export function convertDataToTyped(data: any[][], valueTypes: string[], convertAllToString: boolean = false): any[][] {
  // Convert content to proper types (mutates data in place)
  data.forEach((row, rowIdx) => {
    row.forEach((value, colIdx) => {
      let val = value;
      if (rowIdx > 0 || typeof val !== "string") {
        try {
          switch (valueTypes[colIdx]) {
            case "Boolean":
              if (convertAllToString) {
                val = val ? "TRUE" : "FALSE";
              }
              break;
            case "Double":
              if (convertAllToString) {
                val = String(val);
              }
              break;
            case "Datetime":
              val = convertExcelToISO(val);
              break;
            case "Date":
              val = convertExcelToISO(val);
              val = val.slice(0, val.indexOf("T"));
              break;
            case "Time":
              val = convertExcelToISO(val);
              val = val.slice(val.indexOf("T") + 1, -1);
              break;
          }
        } catch (e) {
          console.log(`Error parsing value: ${value} [row=${rowIdx} col=${colIdx}]`);
          val = val.toString();
        }
      }
      data[rowIdx][colIdx] = val;
    });
  });
  return data;
}

export async function shapeOfRange(fullAddress: string): Promise<number[]> {
  let [sheetName, addr] = splitFullAddress(fullAddress);

  let result: number[] = [];
  await runExcelSafeContext(async (context) => {
    let sheet = context.workbook.worksheets.getItem(sheetName);
    let rng = sheet.getRange(addr);
    rng.load("rowCount");
    rng.load("columnCount");
    await context.sync();
    result = [rng.rowCount, rng.columnCount];
  });
  return result;
}

export async function writeToRange(fullAddress: string, data: any[][], formula = true) {
  let [sheetName, addr] = splitFullAddress(fullAddress);

  await runExcelSafeContext(async (context) => {
    let sheet = context.workbook.worksheets.getItem(sheetName);
    let rng = sheet.getRange(addr);
    if (formula) {
      rng.formulas = data;
    } else {
      rng.values = data;
    }
    await context.sync();
  });
}

export async function writeToCell(fullAddress: string, data: any, python = true, asPyObject = false) {
  let [sheetName, addr] = splitFullAddress(fullAddress);

  // Guard against Ranges
  if (addr.includes(":")) {
    throw Error("Expected single Cell, not Range");
  }

  await runExcelSafeContext(async (context) => {
    let sheet = context.workbook.worksheets.getItem(sheetName);
    let rng = sheet.getRange(addr);
    if (python) {
      rng.formulas = [[`=PY("${data.replace(/"/g, '""')}", ${asPyObject ? 1 : 0})`]];
    } else {
      rng.values = [[data]];
    }
    try {
      await context.sync();
    } catch (e) {
      console.log("Error caught. Writing as text instead.");
      console.log(e);
      // Write the output as text
      rng = sheet.getRange(addr);
      if (python) {
        data = `=PY(${data})`;
      }
      rng.values = [[`'${data}`]];
      rng.format.wrapText = true;
      try {
        await context.sync();
      } catch (e) {
        console.log(`Unable to write to cell ${fullAddress}`);
        console.log(data);
        console.log(e);
      }
    }
  });
}

export async function overwriteSheet(sheetName: string, data: any[][], numberFormat?: string[]) {
  await runExcelSafeContext(async (context) => {
    try {
      let sheet = context.workbook.worksheets.getItem(sheetName);

      // Clear existing data from sheet
      sheet.getUsedRange().clear();
      await context.sync();

      // Write output
      let newRange = sheet.getRangeByIndexes(0, 0, data.length, data[0].length);
      newRange.values = data;
      await context.sync();

      // Apply number formatting
      if (numberFormat) {
        numberFormat.forEach((fmt, col) => {
          let numfmt: any = fmt ?? "General";
          sheet.getRangeByIndexes(0, col, data.length, 1).numberFormat = numfmt;
        });
      }

      // Add filters to columns (if any are non-numeric)
      sheet.autoFilter.apply(newRange);
      await context.sync();
    } catch (error) {
      throw new Error(`OverwriteSheetError: ${error instanceof Error ? error.message : error}`);
    }
  });
}

export async function overwriteTable(tableName: string, data: any[][], numberFormat?: string[]) {
  await runExcelSafeContext(async (context) => {
    try {
      // Read table shape

      // TODO: Enable eslint here and fix explicit load call
      /* eslint-disable office-addins/call-sync-before-read, office-addins/load-object-before-read */
      let table = context.workbook.tables.getItem(tableName);
      let sheet = table.worksheet;
      let range = table.getRange();
      let headRange = table.getHeaderRowRange();
      let bodyRange = table.getDataBodyRange();
      /* eslint-enable office-addins/call-sync-before-read, office-addins/load-object-before-read */

      range.load("columnIndex");
      range.load("rowIndex");
      range.load("address");
      range.load("columnCount");
      range.load("rowCount");
      headRange.load("address");
      bodyRange.load("address");
      await context.sync();

      // Guard against the table expanding into existing data
      let overwrite = "";
      if (data.length > range.rowCount) {
        // Read directly below table
        let rng = sheet.getRangeByIndexes(
          range.rowIndex + range.rowCount,
          range.columnIndex,
          data.length - range.rowCount,
          range.columnCount
        );
        let usedRng = rng.getUsedRangeOrNullObject();
        usedRng.load("address");
        await context.sync();
        if (!usedRng.isNullObject) overwrite = usedRng.address;
      }
      if (data[0].length > range.columnCount) {
        // Read to the right of table (and below)
        let rng = sheet.getRangeByIndexes(
          range.rowIndex,
          range.columnIndex + range.columnCount,
          data.length,
          data[0].length - range.columnCount
        );
        let usedRng = rng.getUsedRangeOrNullObject();
        usedRng.load("address");
        await context.sync();
        if (!usedRng.isNullObject) {
          if (overwrite) overwrite += " and ";
          overwrite += usedRng.address;
        }
      }
      if (overwrite) throw new Error(`Table will expand and overwrite data in ${overwrite}`);

      // Resize table
      let newRange = sheet.getRangeByIndexes(range.rowIndex, range.columnIndex, data.length, data[0].length);
      table.resize(newRange);
      await context.sync();

      // Clear previous table area
      sheet.getRange(headRange.address).clear();
      sheet.getRange(bodyRange.address).clear();
      await context.sync();

      // Write output
      newRange.values = data;

      // Apply number formatting
      if (numberFormat) {
        numberFormat.forEach((fmt, col) => {
          let numfmt: any = fmt ?? "General";
          sheet.getRangeByIndexes(range.rowIndex, range.columnIndex + col, data.length, 1).numberFormat = numfmt;
        });
      }

      await context.sync();
    } catch (error) {
      throw new Error(`OverwriteTableError: ${error instanceof Error ? error.message : error}`);
    }
  });
}

export async function getAliasFromRangeSelection(rs: RangeSelection): Promise<string> {
  switch (rs.rangeType) {
    case RangeType.Sheet:
      return await getFullAddressFromRangeSelection(rs);
    case RangeType.Table:
      rs = await refreshRangeSelection(rs);
      return `${rs.displayName}[#All]`;
    case RangeType.Named:
      return rs.identifier;
    case RangeType.RangeBinding:
    case RangeType.CellBinding:
    case RangeType.SpillBinding:
      return await getFullAddressFromRangeSelection(rs);
  }
}

export async function getFullAddressFromRangeSelection(rs: RangeSelection): Promise<string> {
  switch (rs?.rangeType) {
    case RangeType.Sheet:
      return await runExcelSafeContext(async (context) => {
        let sheet = (await findByIdOrDisplayName(context, rs)) as Excel.Worksheet;
        let rng = sheet.getUsedRange().getBoundingRect("A1");
        rng.load("address");
        await context.sync();
        return rng.address;
      });
    case RangeType.Table:
      return await runExcelSafeContext(async (context) => {
        let table = (await findByIdOrDisplayName(context, rs)) as Excel.Table;
        let rng = table.getRange();
        rng.load("address");
        await context.sync();
        return rng.address;
      });
    case RangeType.Named:
      return await runExcelSafeContext(async (context) => {
        let range = context.workbook.names.getItem(rs.identifier);
        try {
          await context.sync();
        } catch (e) {
          throw Error(`Named Range ${rs.identifier} not found in spreadsheet.`);
        }
        let rng = range.getRange();
        rng.load("address");

        await context.sync();
        return rng.address;
      });
    case RangeType.CellBinding:
    case RangeType.RangeBinding:
    case RangeType.SpillBinding:
      return await getBindingAddress(rs);
  }
}

/**
 * Determine whether an Excel range object is a spill selection
 *
 * The selection must be either:
 *   - the whole spill range
 *   - the single top-left cell of the spill range
 *
 * @param context
 * @param range
 * @returns boolean
 */
export async function isSpillSelection(context: Excel.RequestContext, range: Excel.Range): Promise<boolean> {
  range.load("address");
  const cell = range.getCell(0, 0);
  cell.load("address");
  const anchor = cell.getSpillParentOrNullObject();
  await context.sync();

  if (!anchor.isNullObject) {
    anchor.load("address");
    const spill = anchor.getSpillingToRangeOrNullObject();
    spill.load("address");
    await context.sync();

    return spill.address === range.address || anchor.address === range.address;
  }
  return false;
}

/**
 * Determine whether an Excel range object is a table selection
 *
 * The selection must be either:
 *   - the whole table
 *   - the single top-left cell of the table
 *
 * @param context
 * @param range
 * @returns string | null
 */
export async function isTableSelection(context: Excel.RequestContext, range: Excel.Range): Promise<string | null> {
  range.load("address");
  range.load("rowCount");
  range.load("columnCount");
  await context.sync();
  // If a single cell is selected, look for any table
  // But if a range is selected, the table must be fully contained within the selection
  const fullyContained = range.rowCount > 1 || range.columnCount > 1;
  const tables = range.getTables(fullyContained);
  tables.load("items");
  await context.sync();
  // Look for tables exactly overlapping selection
  for (let tbl of tables.items) {
    const tblRange = tbl.getRange();
    if (fullyContained) {
      tblRange.load("address");
      await context.sync();
      if (tblRange.address == range.address) return tbl.id;
    } else {
      const topLeft = range.getCell(0, 0);
      topLeft.load("address");
      await context.sync();
      if (topLeft.address == range.address) return tbl.id;
    }
  }
  return null;
}

// TODO: we need isTableSelection method to detect a range selection which happens to be a table
//       and integrate it into TableChooser

enum WorkbookStructureElements {
  tables = "tables",
  names = "names",
  sheets = "sheets",
  sheetsWithoutTables = "sheetsWithoutTables",
}

export interface WorkbookStructure extends Record<WorkbookStructureElements, string[]> {}

/**
 * Returns an object like:
 *  {
 *    tables: ["table1", "table2"],
 *    named: ["range1", "customrange"],
 *    sheets: ["Sheet1", "Sheet2", "Fun & Fancy Sheet"],
 *    sheetsWithoutTables: ["Sheet2"],
 *  }
 */
export async function readWorkbookStructure(): Promise<WorkbookStructure> {
  // Get list of sheets, tables, and named ranges in the workbook
  let wbStruct: Record<string, string[]> = {
    tables: [],
    names: [],
    sheets: [],
    sheetsWithoutTables: [],
  };
  await runExcelSafeContext(async (context) => {
    // Fetch the named ranges (workbook scope only)
    context.workbook.load("names");
    await context.sync();
    context.workbook.names.items.forEach((n) => {
      if (n.type === "Range") {
        wbStruct.names.push(n.name);
      }
    });

    // Fetch tables for each sheet
    let sheets = context.workbook.worksheets;
    sheets.load("items/tables/items/name");
    await context.sync();
    // Fetch sheet names
    sheets.items.forEach((s) => s.load("name"));
    await context.sync();
    // Load tables and sheet info into wbStruct
    context.workbook.worksheets.items.forEach((s) => {
      wbStruct.sheets.push(s.name);
      if (s.tables.count > 0) {
        s.tables.items.forEach((t) => {
          wbStruct.tables.push(t.name);
        });
      } else {
        wbStruct.sheetsWithoutTables.push(s.name);
      }
    });
  });
  return wbStruct;
}

export async function createNewSheet(name: string): Promise<void> {
  await runExcelSafeContext(async (context) => {
    context.workbook.worksheets.add(name);
    await context.sync();
  });
}

const letters = Array.from("ABCDEFGHIJKLMNOPQRSTUVWXYZ");
export function indexToCol(idx: number): string {
  const col = [];
  while (idx) {
    idx -= 1; // this is done for every loop
    const remainder = idx % 26;
    col.push(letters[remainder]);
    idx = Math.floor(idx / 26);
  }
  return col.reverse().join("");
}

const letterMap = new Map(letters.map((x, i) => [x, i + 1]));
export function colToIndex(col: string): number {
  let i: number,
    j: number,
    idx = 0,
    numChars = col.length;
  for (i = 0, j = numChars - 1; i < numChars; i += 1, j -= 1) {
    idx += Math.pow(26, j) * letterMap.get(col[i]);
  }
  return idx;
}

const addrPatt = /([A-Z]+)(\d+)/;

// Goes from A1-style addr to [row, col] 0-based indexes
export function addrToIndexes(addr: string): [number, number] {
  const [, colStr, rowStr] = addr.match(addrPatt);
  const col = colToIndex(colStr);
  const row = parseInt(rowStr);
  return [row - 1, col - 1];
}

export function indexesToAddr(row: number, col: number): string {
  return `${indexToCol(col + 1)}${row + 1}`;
}

export async function getCurrentSplitIdAddr(): Promise<SplitIdAddr> {
  try {
    return await runExcelSafeContext(async (context) => {
      const rng = context.workbook.getSelectedRange();
      rng.load("address");
      rng.load("worksheet/id");
      await context.sync();
      const [, addr] = splitFullAddress(rng.address);
      return {
        sheetId: rng.worksheet.id,
        addr: addr,
      };
    });
  } catch (err) {
    console.log(`Error getting current split address: ${err}`);
    return null;
  }
}

export async function getSplitIdAddrFromBinding(bindingId: string, deleteId = false): Promise<SplitIdAddr> {
  /* eslint-disable office-addins/call-sync-before-read */
  /* eslint-disable office-addins/load-object-before-read */
  return await runExcelSafeContext(async (context) => {
    const binding = context.workbook.bindings.getItem(bindingId);
    const range = binding.getRange();
    const worksheet = range.worksheet;
    worksheet.load("id");
    range.load("address");
    if (deleteId) binding.delete();
    await context.sync();

    return {
      sheetId: worksheet.id,
      addr: splitFullAddress(range.address)[1],
    };
  });
}

let _pythonEnabled: boolean | undefined = undefined;
export async function isPythonEnabled() {
  if (_pythonEnabled === undefined) {
    // 2024-09-09 This hack no longer works
    // // Until an official API is created, this is the best hack
    // // to find out if Python is enabled in the workbook.
    // // - Attempt to create a named range with value "=PY()"
    // // - If it failed, this indicates that Python is enabled because Python functions
    // //     cannot be used as a named formula.
    // //   - Return true
    // // - If it succeeded, this indicates that Python is *not* enabled.
    // //     Excel doesn't seem to care if you declare an unknown function.
    // //   - Delete the named range
    // //   - Return false
    // await runExcelSafeContext(async (context) => {
    //   const TEMP_NAME = `__pyxlchecker${Math.random()}__`;
    //   context.workbook.names.add(TEMP_NAME, "=PY()");
    //   try {
    //     await context.sync();
    //     _pythonEnabled = false;
    //     // Clean up
    //     context.workbook.names.getItem(TEMP_NAME).delete();
    //     try {
    //       await context.sync();
    //     } catch (e) {
    //       console.log(`Error caught while deleting TEMP_NAME: ${e}`);
    //     }
    //   } catch (e) {
    //     _pythonEnabled = true;
    //   }
    // });
    return Office.context.platform === Office.PlatformType.PC;
  }
  return _pythonEnabled;
}

export async function getFormulaFromGrid(fullAddress: string): Promise<string> {
  let [sheetName, addr] = splitFullAddress(fullAddress);
  return await runExcelSafeContext(async (context) => {
    let sheet = context.workbook.worksheets.getItem(sheetName);
    let range = sheet.getRange(addr);
    range.load(["formulas"]);
    await context.sync();

    return range.formulas[0][0];
  });
}

export enum FormulaLanguage {
  excel = "excel",
  python = "py",
  r = "r",
}

export function parseFormula(formula: string): { language: FormulaLanguage; formula: string } {
  if (!formula) return { language: null, formula };
  if (formula.startsWith(`=${Env.PYSCRIPT_NAMESPACE}.CODE(`)) {
    const code = convertGridCodeToUserCode(formula);
    return { language: String(code[0]) as FormulaLanguage, formula: code[1] };
  } else if (formula.startsWith("=PY(")) {
    let code = formula.slice(5, -4); // `=PY("...",0)` extract only `...`
    const pyCode = code.replaceAll('""', '"');
    return { language: FormulaLanguage.python, formula: pyCode };
  } else {
    return { language: FormulaLanguage.excel, formula };
  }
}

export function associateCustomFunction(name: string, func: any) {
  try {
    delete CustomFunctions["_association"].mappings[name];
  } catch (err) {
    console.warn(`// Please ignore the [DuplicatedName] warning about ${name}`);
  }
  CustomFunctions.associate(name, func);
}

export function throwErrorInGrid(err: string) {
  if (Office.context.platform !== Office.PlatformType.PC) {
    // Mac is only able to show 254 characters of err
    if (err.length > 254) {
      err = err.slice(0, 9) + "......\n" + err.slice(-238);
    }
  }
  throw new CustomFunctions.Error(CustomFunctions.ErrorCode.invalidValue, err);
}

/**********************************************************
 * Utilities used for Testing
 **********************************************************/

async function testUtilResetWorkbook() {
  // Delete all sheets except Sheet1
  await runExcelSafeContext(async (context) => {
    context.workbook.load("worksheets");
    await context.sync();
    context.workbook.worksheets.items.forEach((sheet) => {
      if (sheet.name !== "Sheet1") sheet.delete();
    });
    await context.sync();
  });
  // Delete any content in Sheet1
  const sheet1Rng = await getFullAddressFromRangeSelection({
    rangeType: RangeType.Sheet,
    identifier: "Sheet1",
  });
  let [sheetName, addr] = splitFullAddress(sheet1Rng);
  await runExcelSafeContext(async (context) => {
    let sheet = context.workbook.worksheets.getItem(sheetName);
    let rng = sheet.getRange(addr);
    rng.delete("Up");
    await context.sync();
  });
}
(g as any).testUtilResetWorkbook = testUtilResetWorkbook;

// Expose common functions
(g as any).testUtilGetFullAddressFromRangeSelection = getFullAddressFromRangeSelection;
(g as any).testUtilShapeOfRange = shapeOfRange;
(g as any).testUtilSplitFullAddress = splitFullAddress;
(g as any).testUtilOverwriteSheet = overwriteSheet;
(g as any).testUtilReadFromRange = readFromRange;
(g as any).testUtilCreateNewSheet = createNewSheet;
