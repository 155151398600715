import { runExcelSafeContext, RangeType, parseFormula, FormulaLanguage } from "xlcommon/src/excel/excel-grid-utils";
import { BaseChart, BaseSetup, BaseDesign } from "../taskpane/hooks/plots/PlotTypes";
import { PlotType } from "../data/plot-types";
import { ValueTracker } from "../taskpane/pages/Visualization/MVCShared/types";
import { buildPythonInExcelCode } from "../taskpane/pages/Visualization/MVCShared/CodeBuilder";
import { splitFullAddress, addrToIndexes } from "xlcommon/src/excel/excel-grid-utils";
import { clearBindingDataFromExcel } from "../excel/grid-utils";

const DEBUG = false;

const VIZDEF_PREFIX = "VIZDEF:";

export interface VizDefIdentifier {
  bindId: string;
  sheetName: string;
  addr: string;
  isoTimestamp: string;
  plotType: PlotType;
  formula: string;
}

export interface VizDef {
  setup: Record<string, any>;
  design: Record<string, any>;
  common: Record<string, any>;
  writtenFormula: string;
  isoTimestamp: string;
}

async function writeVizDefSetting(bindId: string, vizdef: VizDef): Promise<void> {
  return await runExcelSafeContext(async (context) => {
    const settings = context.workbook.settings;
    let serialized = JSON.stringify(vizdef);
    if (DEBUG) console.log(`writing vizdef: ${JSON.stringify(serialized)}`);
    settings.add(`${VIZDEF_PREFIX}${bindId}`, serialized);
    await context.sync();
  });
}

async function deleteVizDefSetting(bindId: string): Promise<void> {
  return await runExcelSafeContext(async (context) => {
    const settings = context.workbook.settings;
    const savedVizDef = settings.getItemOrNullObject(`${VIZDEF_PREFIX}${bindId}`);
    await context.sync();

    if (!savedVizDef.isNullObject) {
      savedVizDef.delete();
      await context.sync();
    }
  });
}

async function sortVizDefIds(vdi: VizDefIdentifier[]) {
  await runExcelSafeContext(async (context) => {
    // Get sheet names in order
    const sheets = context.workbook.worksheets;
    sheets.load("items/name");
    await context.sync();
    const sheetLookup = Object.fromEntries(
      sheets.items.map((sheet, i) => {
        return [sheet.name, i];
      })
    );
    // Sort based on sheet position, then row, then column
    vdi.sort((a, b) => {
      if (a.sheetName === b.sheetName) {
        // Sort based on row, then column
        const [aRow, aCol] = addrToIndexes(a.addr);
        const [bRow, bCol] = addrToIndexes(b.addr);
        return aRow < bRow ? -1 : aRow > bRow ? +1 : aCol < bCol ? -1 : +1;
      } else {
        // Sort based on sheet position
        return sheetLookup[a.sheetName] < sheetLookup[b.sheetName] ? -1 : +1;
      }
    });
  });
}

export default class PlotService {
  // TODO: Error handling done at UI level
  // Note: Each vizdef is stored as a separate setting, each with a common prefix to identify them

  static async listVizDefs(): Promise<VizDefIdentifier[]> {
    if (DEBUG) console.log("listVizDef");
    const vizinfo: VizDefIdentifier[] = [];
    await runExcelSafeContext(async (context) => {
      // Make all settings keys available
      const settings = context.workbook.settings;
      settings.load("items/key");
      await context.sync();
      // Find all settings which start with VIZDEF_PREFIX
      const temp: [string, Excel.Setting, Excel.Binding][] = [];
      settings.items.forEach((setting) => {
        if (setting.key.startsWith(VIZDEF_PREFIX)) {
          // Extract bindId from setting key and attempt to get the associated binding
          const bindId = setting.key.slice(VIZDEF_PREFIX.length);
          const binding = context.workbook.bindings.getItemOrNullObject(bindId);
          temp.push([bindId, setting, binding]);
        }
      });
      await context.sync();

      // Get range associated with binding
      const vizdefs: [string, Excel.Setting, Excel.Range][] = [];
      for (let [bindId, setting, binding] of temp) {
        if (binding.isNullObject) {
          if (DEBUG) console.log(`Binding for saved vizdef is null -- deleting setting`);
          // Remove viz def setting
          await deleteVizDefSetting(bindId);
        } else {
          // Get range associated with binding
          const rng = binding.getRange();
          rng.load("address,formulas");
          // Load the value for setting so it can be read and parsed in the next step
          setting.load("value");
          vizdefs.push([bindId, setting, rng]);
        }
      }
      await context.sync();

      // Extract info from viz defs
      vizdefs.forEach(([bindId, setting, range]) => {
        try {
          const [sheetName, addr] = splitFullAddress(range.address);
          const data = JSON.parse(setting.value) as VizDef;
          const rawFormula = range.formulas[0][0];
          const { language, formula } = parseFormula(rawFormula);
          if (language !== FormulaLanguage.python) {
            // Vizdef has been deleted or modified to something unrecognizable
            deleteVizDefSetting(bindId);
          } else {
            vizinfo.push({
              bindId,
              sheetName,
              addr,
              isoTimestamp: data.isoTimestamp,
              plotType: data.common.plotType,
              formula,
            });
          }
        } catch (err) {
          console.log(`Unable to parse saved viz defintion: ${setting.value}`);
        }
      });
    });
    await sortVizDefIds(vizinfo); // sorts inplace
    return vizinfo;
  }

  static async getVizDef(bindId: string): Promise<VizDef | null> {
    if (DEBUG) console.log(`getVizDef for ${bindId}`);
    return await runExcelSafeContext(async (context) => {
      const settings = context.workbook.settings;
      const savedVizDef = settings.getItemOrNullObject(`${VIZDEF_PREFIX}${bindId}`);
      savedVizDef.load("value"); // Represents the value stored for this setting
      await context.sync();

      if (savedVizDef.isNullObject) {
        return null;
      } else {
        return JSON.parse(savedVizDef.value) as VizDef;
      }
    });
  }

  static async saveVizDef(chart: BaseChart<BaseSetup, BaseDesign>): Promise<void> {
    if (DEBUG) console.log(`saveVizDef: ${JSON.stringify(chart)}`);
    // Verify that output cell exists and is a Binding
    const outputCell = chart.common.outputCell;
    if (!outputCell || outputCell.rangeType !== RangeType.CellBinding) {
      if (DEBUG) console.log(`Output cell is not defined or is wrong type: ${JSON.stringify(outputCell)}`);
      return;
    }
    // Construct RawVizDef, removing any ValueTracker items which haven't been modified
    const vizdef: VizDef = {
      setup: {},
      design: {},
      common: {},
      writtenFormula: chart.common.typedCode ?? (await buildPythonInExcelCode(chart.codeFragments)),
      isoTimestamp: new Date().toISOString(),
    };
    for (let kind of ["setup", "design", "common"]) {
      Object.entries(chart[kind]).forEach(([key, value]) => {
        if (value instanceof ValueTracker) {
          if (value.isUpdated) {
            vizdef[kind][key] = value.value;
          }
        } else {
          vizdef[kind][key] = value;
        }
      });
    }
    await writeVizDefSetting(outputCell.identifier, vizdef);
  }

  static async areBindingAddressesSame(binding1Id, binding2Id) {
    try {
      return await runExcelSafeContext(async (context) => {
        // Get the first address by its ID
        let binding1 = context.workbook.bindings.getItem(binding1Id);
        let range1 = binding1.getRange();
        range1.load("address");

        // Get the second address by its ID
        let binding2 = context.workbook.bindings.getItem(binding2Id);
        let range2 = binding2.getRange();
        range2.load("address");

        await context.sync();

        // Compare the addresses of both bindings
        if (range1.address === range2.address) {
          console.log("The bindings refer to the same range.");
          return true;
        } else {
          console.log("The bindings refer to different ranges.");
          return false;
        }
      });
    } catch (error) {
      console.error("Error comparing binding addresses: ", error);
      return false;
    }
  }

  static async cloneVizDef(newBindId: VizDefIdentifier["bindId"], currentBindId: VizDefIdentifier["bindId"]) {
    if (DEBUG) console.log(`cloneVizDef for ${currentBindId} to ${newBindId}`);
    if (await this.areBindingAddressesSame(newBindId, currentBindId)) {
      throw new Error("Address Error: The new binding address is the same as the current binding address.");
    }
    const vizdef: VizDef = await PlotService.getVizDef(currentBindId);
    if (vizdef) {
      // Use to update output cell identifier
      // vizdef.common.outputCell.identifier = newBindId;
      await writeVizDefSetting(newBindId, vizdef);
    }
  }

  static async deleteVizDef(bindId: string): Promise<void> {
    if (DEBUG) console.log(`deleteVizDef for ${bindId}`);
    await deleteVizDefSetting(bindId);
    await clearBindingDataFromExcel(bindId);
  }
}

// Make PlotService available in the console when debugging
if (DEBUG) {
  (window as any).PlotService = PlotService;
}
