import React, { FC, ReactElement, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Menu,
  MenuList,
  MenuItem,
  MenuPopover,
  MenuDivider,
  Tooltip,
  MenuTrigger,
} from "@fluentui/react-components";
import { LineHorizontal320Filled } from "@fluentui/react-icons";
import { usePython } from "../../hooks/usePython";
// import { logout } from "../../../auth/auth-api";
import { NavMenuItems } from "./nav-models";
import Env from "xlcommon/src/environ";
import "./NavMenu.scss";

/**
 * Component Reference:
 * https://react.fluentui.dev/?path=/docs/components-menu-menu--default#anchor-to-custom-target
 * @returns FC
 */
export const CollapsedNavMenu: FC = () => {
  const [activeClass, setActiveClass] = useState<{ className: string; title: string }>({ className: "", title: "" });

  const navigate = useNavigate();
  const { hasPython } = usePython();

  return (
    <div className="nav-menu">
      <Menu>
        <MenuTrigger>
          <Button
            icon={<LineHorizontal320Filled />}
            appearance="transparent"
            // Avoid (i) button in Excel
            style={{ marginRight: 20 }}
          />
        </MenuTrigger>
        <MenuPopover>
          <MenuList style={{ padding: 8, minWidth: 200 }}>
            {NavMenuItems.map((item) => {
              const disabled = item.disabled || (item.requiresPython && !hasPython && !Env.UNLOCKED);
              const defaultClassName = disabled ? "disabled" : "";
              const activeClassName = activeClass.title === item.title ? activeClass.className : defaultClassName;
              return (
                <div key={item.title}>
                  {item.newSection && <MenuDivider />}
                  <WithTooltip title={item.title} disabled={disabled}>
                    <MenuItem
                      className={activeClassName}
                      style={{ marginBottom: 8 }}
                      disabled={disabled}
                      // Dynamic class name is a means of getting the custom SVG to change color on MenuItem hover :(
                      icon={<item.Icon className={activeClassName} />}
                      onMouseEnter={() =>
                        setActiveClass({ className: disabled ? "disabled" : "active", title: item.title })
                      }
                      onMouseLeave={() => setActiveClass({ className: defaultClassName, title: item.title })}
                      onClick={() => navigate(item.path)}
                    >
                      {item.title}
                    </MenuItem>
                  </WithTooltip>
                </div>
              );
            })}
          </MenuList>
        </MenuPopover>
      </Menu>
    </div>
  );
};

const WithTooltip = ({ title, disabled, children }: { title: string; disabled: boolean; children: ReactElement }) =>
  disabled ? (
    <Tooltip content={`${title} requires Python`} relationship="description" withArrow={true} positioning="above-end">
      {children}
    </Tooltip>
  ) : (
    children
  );
