import React, { ReactElement } from "react";
import { Button, Dropdown, Option, Input, Tooltip, Label } from "@fluentui/react-components";
import { ChevronDown20Filled, ChevronUp20Filled } from "@fluentui/react-icons";
import { Row } from "../../../components/Layout/Space";
import { IDropdownOption } from "@fluentui/react";
import "../Viz.scss";

interface DropdownProps<T> {
  label: string;
  labelWidth: number;
  options: T[];
  value: string;
  isDisabled?: boolean;
  onChange: (event, data) => void;
  dataTestID?: string;
  placeholder?: string;
  multiselect?: boolean;
  selectedOptions?: string[];
}

export const CustomDropdown: React.FC<DropdownProps<IDropdownOption | string>> = ({
  label,
  labelWidth,
  placeholder,
  options,
  value,
  isDisabled,
  onChange,
  dataTestID,
  multiselect,
  selectedOptions,
}) => {
  const formatSelectedValues = (selectedValues: string | string[]): string => {
    if (Array.isArray(selectedValues)) {
      return selectedValues.join(", ");
    }
  };

  return (
    <div style={{ margin: "5px 0 5px 0" }}>
      <Row justifyContent="space-between">
        <label className="label" style={{ minWidth: labelWidth }}>
          {label}
        </label>
        <Dropdown
          data-testid={dataTestID}
          placeholder={placeholder}
          value={multiselect ? formatSelectedValues(value as readonly string[] & string) : (value as string)}
          disabled={isDisabled}
          onOptionSelect={onChange}
          multiselect={multiselect}
          selectedOptions={selectedOptions}
          style={{ minWidth: 135, width: "100%", maxHeight: multiselect ? "100%" : 30 }}
          positioning={{ pinned: true }}
          listbox={{ style: { padding: 5 } }}
        >
          {options.map((option, i) => (
            <Option
              key={i}
              text={typeof option === "string" ? option : option["text"]}
              data-testid={typeof option === "string" ? option : option["text"]}
            >
              {typeof option !== "string" && option.data && option.data.component
                ? option.data.component
                : typeof option === "string"
                ? option
                : option["text"]}
            </Option>
          ))}
        </Dropdown>
      </Row>
    </div>
  );
};

interface DesignInputProps {
  placeholder: string;
  label: string;
  labelWidth: number;
  value: string;
  isDisabled?: boolean;
  onInput: (event: React.FormEvent<HTMLInputElement>) => void;
  dataTestID;
}

export const DesignInputs: React.FC<DesignInputProps> = ({
  placeholder,
  label,
  labelWidth,
  value,
  isDisabled,
  onInput,
  dataTestID,
}) => {
  return (
    <div style={{ margin: "5px 0 5px 0" }}>
      <Row justifyContent="space-between">
        <label className="label" style={{ minWidth: labelWidth }}>
          {label}
        </label>
        <Input
          data-testid={dataTestID}
          value={value}
          placeholder={placeholder}
          onInput={onInput}
          disabled={isDisabled}
          style={{ minWidth: 135, width: "100%", maxHeight: 30 }}
        />
      </Row>
    </div>
  );
};

export const CollapsibleSection = ({
  children,
  isCollapsed,
  toggleCollapse,
  label = "Advanced",
  tooltip,
}: {
  children: React.ReactNode;
  isCollapsed?: boolean;
  toggleCollapse?: () => void;
  label?: string;
  tooltip?: string;
}) => {
  return (
    <>
      <Row gap={5} justifyContent="space-between">
        {tooltip ? (
          <Tooltip content={tooltip} positioning="below-start" relationship="label" withArrow={true}>
            <Label style={{ paddingTop: 5 }}>{label}</Label>
          </Tooltip>
        ) : (
          <Label style={{ paddingTop: 5 }}>{label}</Label>
        )}
        <Button
          data-testid="advanced-button"
          icon={isCollapsed ? <ChevronDown20Filled /> : <ChevronUp20Filled />}
          onClick={toggleCollapse}
          appearance="transparent"
          style={{ border: "None" }}
        ></Button>
      </Row>
      <div className="collapsible-content">{!isCollapsed && children}</div>
    </>
  );
};

export const WithTooltip = ({
  info,
  disabled,
  children,
}: {
  info: string;
  disabled: boolean;
  children: ReactElement;
}) =>
  disabled ? (
    <Tooltip content={info} relationship="description" withArrow={true} positioning="below-end">
      {children}
    </Tooltip>
  ) : (
    children
  );
