import { RangeSelection, getFullAddressFromRangeSelection, readFromRange } from "xlcommon/src/excel/excel-grid-utils";
import { ChartAttr, Dependency } from "./types";
import { ValueTracker } from "./types";

/****************
 * Dependency Constructors
 ****************/

export function dependencyEqualsValue(object: ChartAttr, value: any): Dependency {
  return {
    object: object,
    isSatisfied: () => {
      const val = object.component instanceof ValueTracker ? object.component.value : object.component;
      return val === value;
    },
  };
}

export function dependencyNotEqualsValue(object: ChartAttr, excludedValue: any): Dependency {
  return {
    object: object,
    isSatisfied: () => {
      const val = object.component instanceof ValueTracker ? object.component.value : object.component;
      return val !== excludedValue;
    },
  };
}

export function dependencyInValues(object: ChartAttr, allowedValues: any[]): Dependency {
  return {
    object: object,
    isSatisfied: () => {
      const val = object.component instanceof ValueTracker ? object.component.value : object.component;
      return allowedValues.includes(val);
    },
  };
}

/****************
 * Other Utilities
 ****************/

export const fetchHeaders = async (
  dataRange: RangeSelection,
  hasHeaders: boolean,
  updatePlot: (updates: Record<string, any>) => void
) => {
  const fullAddress = await getFullAddressFromRangeSelection(dataRange);
  if (fullAddress === undefined) return;
  // Read one row of data
  const data = await readFromRange(fullAddress, 1);
  let updatedHeaders: string[] = [];
  if (hasHeaders) {
    updatedHeaders = data[0].filter((item) => typeof item === "string" && item != "");
  } else {
    for (let i = 0; i < data[0].length; i++) {
      updatedHeaders.push(`Column ${(i + 1).toString()}`);
    }
  }

  updatePlot({ headers: updatedHeaders });
  return updatedHeaders;
};
