import { useEffect } from "react";
import { PlotType } from "../../../../data/plot-types";
import { RangeSelection } from "xlcommon/src/excel/excel-grid-utils";
import { dependencyEqualsValue, dependencyNotEqualsValue, fetchHeaders } from "../MVCShared/PlotGeneratorUtils";
import { Chart, LegendPosition, Orientation } from "../MVCShared/types";
import {
  DataRangeAttr,
  DividerAttr,
  DropdownAttr,
  HeadingAttr,
  LabelAttr,
  OutputAttr,
  PaletteAttr,
  CheckBoxAttr,
  SpinnerAttr,
  ColorPickerAttr,
  AxisDropdownAttr,
  LegendAttr,
  CollapsibleAttr,
  GridlinesAttr,
  SingleChartBorders,
} from "../MVCShared/PlotAttributes";
import { buildReactFromAttrs, buildCode } from "../MVCShared/CodeBuilder";
import { useChart } from "../../../../taskpane/hooks/plots/useCentralViz";
import { CountSetup, CountDesign as ICountDesign } from "../../../../taskpane/hooks/plots/useCount";

const CountContext = (): Chart => {
  const { setup, design, common, updateDesign, updateSetup, updateCommon, setCodeFragments } = useChart<
    CountSetup,
    ICountDesign
  >(PlotType.count);

  useEffect(() => {
    (async () => {
      const cb = await buildCode(common, [...countChart.baseAttrs, ...countChart.designAttrs]);
      setCodeFragments(cb);
    })();
  }, [setup, design, common]);

  useEffect(() => {
    (async () => {
      await fetchHeaders(common.inputData, common.hasHeaders, updateSetup);
    })();
  }, [common.hasHeaders, common.inputData]);

  const orientationDropdown = DropdownAttr({
    value: setup.orientation,
    onChange: (_, data) => updateSetup({ orientation: data.optionValue }),
    label: "Orientation",
    options: ["Horizontal", "Vertical"],
    codeKey: "orient",
    codeValueMap: Orientation,
    codeRequiresInteraction: true,
    dataTestID: "orientation",
  });

  const xAxis = AxisDropdownAttr({
    value: setup.xAxis,
    onChange: (_, data) => {
      updateSetup({ xAxis: data.optionValue }, "--Select--");
      updateDesign({ xAxisLabel: data.optionValue }, "--Select--");
    },
    label: "X-Axis",
    options: ["--Select--", ...setup.headers],
    codeKey: "x",
    visibleDependencies: [dependencyEqualsValue(orientationDropdown, "Vertical")],
    dataTestID: "x-axis",
    hasHeaders: common.hasHeaders,
  });

  const yAxis = AxisDropdownAttr({
    value: setup.yAxis,
    onChange: (_, data) => {
      updateSetup({ yAxis: data.optionValue }, "--Select--");
      updateDesign({ yAxisLabel: data.optionValue }, "--Select--");
    },
    label: "Y-Axis",
    options: ["--Select--", ...setup.headers],
    codeKey: "y",
    visibleDependencies: [dependencyEqualsValue(orientationDropdown, "Horizontal")],
    dataTestID: "y-axis",
    hasHeaders: common.hasHeaders,
  });

  const SaturationAttr = SpinnerAttr({
    label: "Saturation",
    value: design.saturation,
    step: 0.01,
    max: 1,
    onChange: (_, data) => updateDesign({ saturation: data }),
    codeKey: "saturation",
    codeRequiresInteraction: true,
  });

  const Legend = LegendAttr({
    value: design.legendPosition,
    label: "Legend Position",
    onChange: (_, data) => updateDesign({ legendPosition: data.optionValue }, "--Select--"),
    callKey: "move_legend",
    codeValueMap: LegendPosition,
    topValue: design.topPosition,
    rightValue: design.rightPosition,
    codeRequiresInteraction: true,
  });

  const colorBy = AxisDropdownAttr({
    value: setup.colorBy,
    onChange: (_, data) => updateSetup({ colorBy: data.optionValue }, "--None--"),
    label: "Color By",
    options: ["--None--", ...setup.headers],
    placeholder: "--None--",
    codeKey: "hue",
    hasHeaders: common.hasHeaders,
  });

  const countChart: Chart = {
    baseAttrs: [
      DataRangeAttr({
        inputData: common.inputData,
        onChangeSelection: (newSelection: RangeSelection) => updateCommon({ inputData: newSelection }),
      }),
      CheckBoxAttr({
        label: "Has headers",
        value: common.hasHeaders,
        onChange: (_, e) => updateCommon({ hasHeaders: e.checked }),
        dataTestID: "headers",
      }),
      OutputAttr({
        outputCell: common.outputCell,
        onChange: (newSelection: RangeSelection) => updateCommon({ outputCell: newSelection }),
      }),
      orientationDropdown,
      DividerAttr(),
      HeadingAttr({
        title: "Data",
        tooltip: "Select data cells and parameters",
      }),
      xAxis,
      yAxis,
      colorBy,
    ],
    designAttrs: [
      LabelAttr({
        value: design.plotTitle,
        placeholder: "Title",
        label: "Title",
        codeKey: "title",
        onChange: (event) => updateDesign({ plotTitle: event.currentTarget.value }),
      }),
      LabelAttr({
        value: design.xAxisLabel,
        placeholder: "Defaults header",
        label: "X-Axis Label",
        codeKey: "xlabel",
        onChange: (event) => updateDesign({ xAxisLabel: event.currentTarget.value }),
        visibleDependencies: [dependencyEqualsValue(orientationDropdown, "Vertical")],
      }),
      LabelAttr({
        value: design.yAxisLabel,
        placeholder: "Defaults header",
        label: "Y-Axis Label",
        codeKey: "ylabel",
        onChange: (event) => updateDesign({ yAxisLabel: event.currentTarget.value }),
        visibleDependencies: [dependencyEqualsValue(orientationDropdown, "Horizontal")],
      }),
      DividerAttr(),
      CollapsibleAttr({
        collapsed: design.borderCollapsed,
        label: "Border",
        toggle: () => {
          updateDesign({ borderCollapsed: !design.borderCollapsed });
        },
        children: [
          SingleChartBorders({
            label: "Top",
            value: design.topSpine,
            onChange: (_, e) => updateDesign({ topSpine: e.checked }),
            callKey: "top",
          }),
          SingleChartBorders({
            label: "Right",
            value: design.rightSpine,
            onChange: (_, e) => updateDesign({ rightSpine: e.checked }),
            callKey: "right",
          }),
          SingleChartBorders({
            label: "Bottom",
            value: design.bottomSpine,
            onChange: (_, e) => updateDesign({ bottomSpine: e.checked }),
            callKey: "bottom",
          }),
          SingleChartBorders({
            label: "Left",
            value: design.leftSpine,
            onChange: (_, e) => updateDesign({ leftSpine: e.checked }),
            callKey: "left",
          }),
        ],
      }),
      DividerAttr(),
      CollapsibleAttr({
        collapsed: design.gridlinesCollapsed,
        label: "Gridlines",
        toggle: () => {
          updateDesign({ gridlinesCollapsed: !design.gridlinesCollapsed });
        },
        children: [
          GridlinesAttr({
            majorHorizontal: design.majorHorizontal,
            majorVertical: design.majorVertical,
            minorHorizontal: design.minorHorizontal,
            minorVertical: design.minorVertical,
            onChange: (key, event) => {
              updateDesign({ [key]: event.checked });
            },
          }),
        ],
      }),
      DividerAttr(),
      HeadingAttr({ title: "Axes Label Rotation" }),
      SpinnerAttr({
        label: "X-Ticks",
        value: design.xticks,
        step: 5,
        max: 180,
        min: -180,
        onChange: (_, data) => updateDesign({ xticks: parseInt(data) }),
        callKey: "xticks",
        suffix: "°",
        codeRequiresInteraction: true,
      }),
      SpinnerAttr({
        label: "Y-Ticks",
        value: design.yticks,
        step: 5,
        max: 180,
        min: -180,
        onChange: (_, data) => updateDesign({ yticks: parseInt(data) }),
        callKey: "yticks",
        suffix: "°",
        codeRequiresInteraction: true,
      }),
      DividerAttr(),
      HeadingAttr({
        title: "Color",
        tooltip: null,
      }),
      CheckBoxAttr({
        value: design.fill,
        onChange: (_, e) => updateDesign({ fill: e.checked }),
        label: "Fill",
        codeKey: "fill",
        codeRequiresInteraction: true,
      }),
      PaletteAttr({
        value: design.palette,
        onChange: (_, data) => updateDesign({ palette: data.optionText }),
        codeKey: "palette",
        placeholder: "Accent",
        visibleDependencies: [dependencyNotEqualsValue(colorBy, "")],
        codeRequiresInteraction: true,
        dataTestID: "palette",
      }),
      ColorPickerAttr({
        value: design.color,
        label: "Color",
        onChange: (hexColor) => updateDesign({ color: `#${hexColor}` }),
        codeKey: "color",
        visibleDependencies: [dependencyEqualsValue(colorBy, "")],
        codeRequiresInteraction: true,
      }),
      SaturationAttr,
      // DropdownAttr({
      //   value: design.colorOrder,
      //   onChange: (_, data) => {
      //     updateDesign({ colorOrder: data.optionValue });
      //   },
      //   label: "Color Order",
      //   options: colorOrder,
      //   placeholder: "None",
      // }),
      // Included in the figma but might need more logic to be implemented
      DividerAttr(),
      HeadingAttr({
        title: "Bar Formatting",
        tooltip: null,
      }),
      SpinnerAttr({
        label: "Width",
        value: design.width,
        step: 0.1,
        max: 2 ** 32 - 1,
        onChange: (_, data) => updateDesign({ width: data }),
        codeKey: "width",
        codeRequiresInteraction: true,
      }),
      CheckBoxAttr({
        value: design.dodge,
        onChange: (_, e) => updateDesign({ dodge: e.checked }),
        label: "Dodge",
        codeKey: "dodge",
        codeRequiresInteraction: true,
        dataTestID: "dodge",
      }),
      DividerAttr(),
      HeadingAttr({ title: "Legend" }),
      Legend,
      DividerAttr(),
      CollapsibleAttr({
        collapsed: design.isCollapsed,
        toggle: () => {
          updateDesign({ isCollapsed: !design.isCollapsed });
        },
        children: [
          HeadingAttr({
            title: "Legend",
            tooltip:
              "Use the Top and Right fields for more fine-grained control, including moving the legend outside of the axes.",
          }),
          SpinnerAttr({
            label: "Top",
            value: design.topPosition,
            onChange: (_, data) => updateDesign({ topPosition: data }),
            step: 0.1,
            max: 2,
            codeRequiresInteraction: true,
            enabledDependencies: [dependencyNotEqualsValue(Legend, "--Select--")],
          }),
          SpinnerAttr({
            label: "Right",
            value: design.rightPosition,
            step: 0.1,
            max: 2,
            onChange: (_, data) => updateDesign({ rightPosition: data }),
            codeRequiresInteraction: true,
            enabledDependencies: [dependencyNotEqualsValue(Legend, "--Select--")],
          }),
        ],
      }),
    ],
  };
  return countChart;
};

const CountForm = () => {
  const countChart = CountContext();
  return buildReactFromAttrs(countChart.baseAttrs, 100);
};

export const CountDesign = () => {
  const countDesign = CountContext();
  return buildReactFromAttrs(countDesign.designAttrs, 120);
};

export default CountForm;
