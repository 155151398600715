import { useEffect } from "react";
import { PlotType } from "../../../../data/plot-types";
import { RangeSelection } from "xlcommon/src/excel/excel-grid-utils";
import { useChart } from "../../../../taskpane/hooks/plots/useCentralViz";
import { Chart, LegendPosition } from "../MVCShared/types";
import {
  AxisDropdownAttr,
  CheckBoxAttr,
  CollapsibleAttr,
  ColorPickerAttr,
  DataRangeAttr,
  DividerAttr,
  DropdownAttr,
  HeadingAttr,
  LabelAttr,
  LegendAttr,
  OutputAttr,
  PaletteAttr,
  SpinnerAttr,
  GridlinesAttr,
  MultiChartBorders,
} from "../MVCShared/PlotAttributes";
import { buildCode, buildReactFromAttrs } from "../MVCShared/CodeBuilder";
import { dependencyEqualsValue, dependencyNotEqualsValue, fetchHeaders } from "../MVCShared/PlotGeneratorUtils";
import { Facet } from "./FacetGridAttr";
import { DisplotSetup, DisplotDesign as IDisplotDesign } from "../../../../taskpane/hooks/plots/useDisplot";

const DisplotContext = (): Chart => {
  const { setup, design, common, updateDesign, updateSetup, updateCommon, setCodeFragments } = useChart<
    DisplotSetup,
    IDisplotDesign
  >(PlotType.distribution);

  useEffect(() => {
    (async () => {
      const cb = await buildCode(common, [...DisplotChart.baseAttrs, ...DisplotChart.designAttrs]);
      setCodeFragments(cb);
    })();
  }, [setup, design, common]);

  useEffect(() => {
    (async () => {
      await fetchHeaders(common.inputData, common.hasHeaders, updateSetup);
    })();
  }, [common.hasHeaders, common.inputData]);

  const ColorBy = AxisDropdownAttr({
    value: setup.colorBy,
    onChange: (_, data) => updateSetup({ colorBy: data.optionValue }, "--None--"),
    label: "Color By",
    options: ["--None--", ...setup.headers],
    placeholder: "--None--",
    codeKey: "hue",
    hasHeaders: common.hasHeaders,
  });

  const LegendCheckbox = CheckBoxAttr({
    value: design.legend,
    onChange: (_, e) => updateDesign({ legend: e.checked }),
    label: "Legend",
    codeKey: "legend",
    codeRequiresInteraction: true,
    dataTestID: "legend",
  });

  const Legend = LegendAttr({
    value: design.legendPosition,
    label: "Legend Position",
    onChange: (_, data) => updateDesign({ legendPosition: data.optionValue }, "--Select--"),
    callKey: "move_legend",
    codeValueMap: LegendPosition,
    topValue: design.topPosition,
    rightValue: design.rightPosition,
    codeRequiresInteraction: true,
    enabledDependencies: [dependencyNotEqualsValue(LegendCheckbox, false)],
  });

  const DisplotChart: Chart = {
    baseAttrs: [
      DataRangeAttr({
        inputData: common.inputData,
        onChangeSelection: (newSelection: RangeSelection) => updateCommon({ inputData: newSelection }),
      }),
      CheckBoxAttr({
        label: "Has headers",
        value: common.hasHeaders,
        onChange: (_, e) => updateCommon({ hasHeaders: e.checked }),
        dataTestID: "headers",
      }),
      OutputAttr({
        outputCell: common.outputCell,
        onChange: (newSelection: RangeSelection) => updateCommon({ outputCell: newSelection }),
      }),
      DividerAttr(),
      HeadingAttr({ title: "Data", tooltip: "Select data cells and parameters" }),
      DropdownAttr({
        value: setup.xAxis,
        onChange: (_, data) => {
          updateSetup({ xAxis: data.optionValue }, "--Select--");
        },
        dataTestID: "x-axis",
        label: "X-Axis",
        options: ["--Select--", ...setup.headers],
        codeKey: "x",
      }),
      DropdownAttr({
        value: setup.yAxis,
        onChange: (_, data) => {
          updateSetup({ yAxis: data.optionValue }, "--Select--");
        },
        dataTestID: "yAxis",
        label: "Y-Axis",
        options: ["--Select--", ...setup.headers],
        codeKey: "y",
      }),
      ColorBy,
      DividerAttr(),
      CollapsibleAttr({
        collapsed: setup.isCollapsed,
        toggle: () => {
          updateSetup({ isCollapsed: !setup.isCollapsed });
        },
        children: [
          DropdownAttr({
            value: setup.col,
            onChange: (_, data) => {
              updateSetup({ col: data.optionValue }, "--Select--");
            },
            dataTestID: "col",
            label: "Facet Grid Column",
            options: ["--Select--", ...setup.headers],
            codeKey: "col",
          }),
          DropdownAttr({
            value: setup.row,
            onChange: (_, data) => {
              updateSetup({ row: data.optionValue }, "--Select--");
            },
            dataTestID: "row",
            label: "Facet Grid Row",
            options: ["--Select--", ...setup.headers],
            codeKey: "row",
          }),
          Facet({
            sharexVal: setup.shareX,
            shareyVal: setup.shareY,
            facetPalette: design.facetPalette,
            xlim: design.xlim,
            ylim: design.ylim,
            codeKey: "facet_kws",
            onChangeSharex: (_, e) => updateSetup({ shareX: e.checked }),
            onChangeSharey: (_, e) => updateSetup({ shareY: e.checked }),
            codeRequiresInteraction: true,
          }),
        ],
      }),
      DividerAttr(),
      HeadingAttr({ title: "Subplots", tooltip: "" }),
      DropdownAttr({
        value: setup.kind,
        onChange: (_, data) => {
          updateSetup({ kind: data.optionValue });
        },
        dataTestID: "kind",
        label: "Kind",
        options: ["ECDF", "Histogram", "KDE"],
        codeValueMap: { ECDF: "ecdf", Histogram: "hist", KDE: "kde" },
        codeRequiresInteraction: true,
        codeKey: "kind",
      }),
      DividerAttr(),
    ],
    designAttrs: [
      LabelAttr({
        value: design.plotTitle,
        placeholder: "Title",
        label: "Title",
        codeKey: "title",
        onChange: (event) => updateDesign({ plotTitle: event.currentTarget.value }),
      }),
      DividerAttr(),
      CollapsibleAttr({
        collapsed: design.borderCollapsed,
        label: "Border",
        toggle: () => {
          updateDesign({ borderCollapsed: !design.borderCollapsed });
        },
        children: [
          MultiChartBorders({
            left: design.leftSpine,
            right: design.rightSpine,
            bottom: design.bottomSpine,
            top: design.topSpine,
            onChange: (key, event) => {
              updateDesign({ [key]: event.checked });
            },
            chartType: PlotType.distribution,
            dataTestID: "spine-checkbox",
          }),
        ],
      }),
      DividerAttr(),
      CollapsibleAttr({
        collapsed: design.gridlinesCollapsed,
        label: "Gridlines",
        toggle: () => {
          updateDesign({ gridlinesCollapsed: !design.gridlinesCollapsed });
        },
        children: [
          GridlinesAttr({
            majorHorizontal: design.majorHorizontal,
            majorVertical: design.majorVertical,
            minorHorizontal: design.minorHorizontal,
            minorVertical: design.minorVertical,
            chartType: PlotType.distribution,
            onChange: (key, event) => {
              updateDesign({ [key]: event.checked });
            },
          }),
        ],
      }),
      DividerAttr(),
      HeadingAttr({ title: "Axes Label Rotation" }),
      SpinnerAttr({
        label: "X-Ticks",
        value: design.xticks,
        step: 5,
        max: 2 ** 32 - 1,
        onChange: (_, data) => updateDesign({ xticks: parseInt(data) }),
        callKey: "xticks",
        suffix: "°",
        codeRequiresInteraction: true,
      }),
      SpinnerAttr({
        label: "Y-Ticks",
        value: design.yticks,
        step: 5,
        max: 2 ** 32 - 1,
        onChange: (_, data) => updateDesign({ yticks: parseInt(data) }),
        callKey: "yticks",
        suffix: "°",
        codeRequiresInteraction: true,
      }),
      DividerAttr(),
      ColorPickerAttr({
        value: design.color,
        label: "Color",
        onChange: (hexColor) => updateDesign({ color: `#${hexColor}` }),
        codeKey: "color",
        visibleDependencies: [dependencyEqualsValue(ColorBy, "")],
        codeRequiresInteraction: true,
      }),
      PaletteAttr({
        value: design.palette,
        onChange: (_, data) => updateDesign({ palette: data.optionText }),
        codeKey: "palette",
        placeholder: "Accent",
        label: "Facet Grid Palette",
        visibleDependencies: [dependencyNotEqualsValue(ColorBy, "")],
        codeRequiresInteraction: true,
      }),
      DividerAttr(),
      HeadingAttr({ title: "Scale", tooltip: "Set chart size" }),
      SpinnerAttr({
        label: "Height",
        value: design.height,
        step: 0.1,
        max: 2 ** 32 - 1,
        suffix: " in",
        onChange: (_, data) => updateDesign({ height: parseFloat(data) }),
        codeKey: "height",
        codeRequiresInteraction: true,
        dataTestID: "height",
      }),
      SpinnerAttr({
        label: "Aspect",
        value: design.aspect,
        step: 0.1,
        suffix: " in",
        max: 2 ** 32 - 1,
        onChange: (_, data) => updateDesign({ aspect: parseFloat(data) }),
        codeKey: "aspect",
        codeRequiresInteraction: true,
        dataTestID: "aspect",
      }),
      SpinnerAttr({
        label: "Column Wrap Width",
        value: design.colWrap,
        step: 1,
        max: 2 ** 32 - 1,
        onChange: (_, data) => updateDesign({ colWrap: data }),
        codeKey: "col_wrap",
        codeRequiresInteraction: true,
        dataTestID: "col-wrap",
      }),
      DividerAttr(),
      HeadingAttr({ title: "Legend Style" }),
      LegendCheckbox,
      Legend,
      DividerAttr(),
      CollapsibleAttr({
        collapsed: design.isCollapsed,
        toggle: () => {
          updateDesign({ isCollapsed: !design.isCollapsed });
        },
        children: [
          HeadingAttr({
            title: "Legend",
            tooltip:
              "Use the Top and Right fields for more fine-grained control, including moving the legend outside of the axes.",
          }),
          SpinnerAttr({
            label: "Top",
            value: design.topPosition,
            onChange: (_, data) => updateDesign({ topPosition: data }),
            step: 0.1,
            max: 2,
            codeRequiresInteraction: true,
            enabledDependencies: [dependencyNotEqualsValue(Legend, "--Select--")],
          }),
          SpinnerAttr({
            label: "Right",
            value: design.rightPosition,
            step: 0.1,
            max: 2,
            onChange: (_, data) => updateDesign({ rightPosition: data }),
            codeRequiresInteraction: true,
            enabledDependencies: [dependencyNotEqualsValue(Legend, "--Select--")],
          }),
          HeadingAttr({ title: "Facet Color" }),
          PaletteAttr({
            value: design.facetPalette,
            onChange: (_, data) => {
              updateDesign({ facetPalette: data.optionText });
            },
            placeholder: "Accent",
            dataTestID: "facetPalette",
          }),
          DividerAttr(),
          HeadingAttr({ title: "Axis Limits" }),
          SpinnerAttr({
            label: "X-Limit",
            value: design.xlim,
            step: 1,
            max: 180,
            min: -180,
            onChange: (_, data) => updateDesign({ xlim: data }),
            codeRequiresInteraction: true,
            dataTestID: "xlim",
          }),
          SpinnerAttr({
            label: "Y-Limit",
            value: design.ylim,
            step: 1,
            max: 180,
            min: -180,
            onChange: (_, data) => updateDesign({ ylim: data }),
            codeRequiresInteraction: true,
            dataTestID: "ylim",
          }),
        ],
      }),
      DividerAttr(),
    ],
  };
  return DisplotChart;
};

const DisplotForm = () => {
  const DisplotChart = DisplotContext();
  return buildReactFromAttrs(DisplotChart.baseAttrs, 130);
};

export const DisplotDesign = () => {
  const DisplotDesign = DisplotContext();
  return buildReactFromAttrs(DisplotDesign.designAttrs, 140);
};

export default DisplotForm;
