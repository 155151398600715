import React, { useEffect } from "react";
import { Button } from "@fluentui/react-components";
import { useNavigate } from "react-router-dom";
import { snakeEyesRecord } from "../../../analytics/snake-eyes";
import { Mode } from "../../types";
import styles from "../../styles.module.scss";
import "./Viz.scss";
import { useChartManager } from "../../hooks/plots/useCentralViz";

interface ActionButtonsProps {
  onPrimaryClick: () => Promise<void>;
  disabled?: boolean;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({ onPrimaryClick, disabled }) => {
  const navigate = useNavigate();
  const { plotType, mode, setPlotType, setTab, setMode, resetContext } = useChartManager();

  useEffect(() => {
    if (!plotType) {
      navigate("/viz");
    }
  }, [plotType]);

  const handleContinueClick = async () => {
    // Reset chart
    resetContext();
    setTab(null);
    setMode(null);
    setPlotType(null);
  };

  return (
    <div className="viz-action">
      <Button
        appearance="primary"
        disabled={disabled}
        iconPosition="after"
        onClick={() => {
          onPrimaryClick();
          snakeEyesRecord({
            event: "viz/chart_created",
          });
        }}
        style={{ padding: 8 }}
      >
        {mode === Mode.Edit ? "Apply" : "Create"}
      </Button>
      <Button
        data-testid="restart"
        appearance="subtle"
        onClick={handleContinueClick}
        style={{ color: styles.gray500, padding: 8 }}
      >
        Cancel
      </Button>
    </div>
  );
};

export default ActionButtons;
