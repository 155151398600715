const PREFIX = "_xltb__";

class Environment {
  has(name: string): boolean {
    return `${PREFIX}${name}` in sessionStorage;
  }
  read(name: string): string {
    const value = sessionStorage.getItem(`${PREFIX}${name}`);
    return value ?? "";
  }
  write(name: string, value: string) {
    sessionStorage.setItem(`${PREFIX}${name}`, value);
  }
  delete(name: string) {
    sessionStorage.removeItem(`${PREFIX}${name}`);
  }

  // These are set in apps/xltb/api/xltb/routes/root_xxx.py:inject_environment_variables
  get ASSISTANT_CLIENT_SOURCE(): string {
    return this.read("ASSISTANT_CLIENT_SOURCE");
  }
  get ASSISTANT_ROUTE_PATH_PREFIX(): string {
    return this.read("ASSISTANT_ROUTE_PATH_PREFIX");
  }
  get BASE_URL(): string {
    return this.read("BASE_URL");
  }
  get CLOUD_URL(): string {
    return this.read("CLOUD_URL");
  }
  get HEAP_ID(): number {
    return parseInt(this.read("HEAP_ID"));
  }
  get PYSCRIPT_NAMESPACE(): string {
    return this.read("PYSCRIPT_NAMESPACE");
  }
  get UNLOCKED(): boolean {
    return this.read("UNLOCKED") === "true";
  }

  get signedIn(): boolean {
    if (!this.has("signedIn")) return false;
    return this.read("signedIn") === "true";
  }

  set signedIn(state: boolean) {
    this.write("signedIn", state ? "true" : "false");
  }

  get pyscriptEnabled(): boolean {
    if (!this.has("pyscriptEnabled")) return false;
    return this.read("pyscriptEnabled") === "true";
  }
  set pyscriptEnabled(state: boolean) {
    this.write("pyscriptEnabled", state ? "true" : "false");
  }
}

const Env = new Environment();
export default Env;
