import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Tab, TabList } from "@fluentui/react-components";
import Code from "./Code";
import BaseDesign from "./BaseDesign";
import BaseSetup from "./BaseSetup";
import { preloadPyWorker } from "./viz-preview";
import Preview from "./Preview";
import { plotsLookup } from "../../../data/plot-types";
import { Mode } from "../../types";
import { useChartManager } from "../../hooks/plots/useCentralViz";
import { VizTabType } from "../../hooks/plots/PlotTypes";
import "./Viz.scss";

function CoreViz() {
  const navigate = useNavigate();
  const { plotType, mode, tab, setTab } = useChartManager();

  useEffect(() => {
    preloadPyWorker();

    // This should only happen on auto-refresh during development
    if (!plotType) navigate("/viz");
  }, []);

  return (
    <div className="core-viz">
      <h2>{`${mode === Mode.Edit ? "Edit" : "Create"} ${plotsLookup[plotType]?.name} Chart`}</h2>
      <TabList selectedValue={tab} onTabSelect={(_, selectedTab) => setTab(selectedTab.value as VizTabType)}>
        <Tab value="setup">Setup</Tab>
        <Tab value="design">Design</Tab>
        <Tab value="code">Code</Tab>
        <Tab value="preview">Preview</Tab>
      </TabList>
      <div className="tab-content">
        {tab === "setup" && <BaseSetup />}
        {tab === "design" && <BaseDesign />}
        {tab === "code" && <Code />}
        {tab === "preview" && <Preview />}
      </div>
    </div>
  );
}

export default CoreViz;
