import { BaseDesign, BaseSetup } from "./PlotTypes";
import { ValueTracker } from "../../pages/Visualization/MVCShared/types";
import styles from "../../styles.module.scss";

export interface RegressionSetup extends BaseSetup {
  // TODO: specifics
  interval: ValueTracker<number>;
  bootstrapSamples: ValueTracker<number>;
  seed: ValueTracker<number>;
  newXAxisScale: ValueTracker<string>;
  newYAxisScale: ValueTracker<string>;
  xPartial: string;
  yPartial: string;
  xJitter: ValueTracker<number>;
  yJitter: ValueTracker<number>;
  function: ValueTracker<string>;
  order: ValueTracker<number>;
  truncate: ValueTracker<boolean>;
  estimator: ValueTracker<string>;
  errorBar: ValueTracker<string>;
  isCollapsed: boolean;
}

export interface RegressionDesign extends BaseDesign {
  // TODO: specifics
  lineWidth: ValueTracker<number>;
  lineStyle: ValueTracker<string>;
  lineLabel: string;
  marker: ValueTracker<string>;
  stroke: ValueTracker<number>;
  gap: ValueTracker<number>;
  errorBarColor: ValueTracker<string>;
  errorBarWidth: ValueTracker<number>;
  errorBarCapWidth: ValueTracker<number>;
  isDesignCollapsed: boolean;
  yticks: ValueTracker<number>;
  xticks: ValueTracker<number>;
}

export const regplotSetup: RegressionSetup = {
  headers: [],
  xAxis: "",
  yAxis: "",
  colorBy: "",
  newXAxisScale: new ValueTracker("Linear"),
  newYAxisScale: new ValueTracker("Linear"),
  xAxisScale: "",
  yAxisScale: "",
  estimator: new ValueTracker("--None--"),
  errorBar: new ValueTracker("--None--"),
  interval: new ValueTracker(95),
  bootstrapSamples: new ValueTracker(1000),
  seed: new ValueTracker(0),
  xPartial: "",
  yPartial: "",
  xJitter: new ValueTracker(0),
  yJitter: new ValueTracker(0),
  function: new ValueTracker("Linear"),
  order: new ValueTracker(2),
  truncate: new ValueTracker(true),
  isCollapsed: true,
};

export const regplotDesign: RegressionDesign = {
  plotTitle: "",
  xAxisLabel: "",
  yAxisLabel: "",
  color: new ValueTracker(styles.seabornDefault),
  marker: new ValueTracker("None"),
  stroke: new ValueTracker(5),
  gap: new ValueTracker(0),
  // In the original figma, cannot find documentation for these
  lineStyle: new ValueTracker("Solid"),
  lineWidth: new ValueTracker(3),
  lineLabel: "",
  errorBarColor: new ValueTracker("black"),
  errorBarWidth: new ValueTracker(2),
  errorBarCapWidth: new ValueTracker(0.5),
  isDesignCollapsed: true,
  yticks: new ValueTracker(0),
  xticks: new ValueTracker(0),
  majorHorizontal: new ValueTracker(false),
  majorVertical: new ValueTracker(false),
  minorHorizontal: new ValueTracker(false),
  minorVertical: new ValueTracker(false),
  leftSpine: true,
  bottomSpine: true,
  topSpine: false,
  rightSpine: false,
  borderCollapsed: true,
  gridlinesCollapsed: true,
};
