import { useEffect } from "react";
import { PlotType } from "../../../../data/plot-types";
import { RangeSelection } from "xlcommon/src/excel/excel-grid-utils";
import { useChart } from "../../../hooks/plots/useCentralViz";
import { Axes, Chart, Estimators, IntervalOptions, Orientation, LegendPosition } from "../MVCShared/types";
import {
  DataRangeAttr,
  DividerAttr,
  DropdownAttr,
  HeadingAttr,
  LabelAttr,
  OutputAttr,
  CollapsibleAttr,
  SpinnerAttr,
  ColorPickerAttr,
  PaletteAttr,
  CheckBoxAttr,
  AxisDropdownAttr,
  LegendAttr,
  ErrorKws,
  GridlinesAttr,
  SingleChartBorders,
} from "../MVCShared/PlotAttributes";
import { buildCode, buildReactFromAttrs, CodeBuilder } from "../MVCShared/CodeBuilder";
import {
  dependencyEqualsValue,
  dependencyNotEqualsValue,
  dependencyInValues,
  fetchHeaders,
} from "../MVCShared/PlotGeneratorUtils";
import { BarDesign as IBarDesign, BarSetup } from "../../../../taskpane/hooks/plots/useBar";

const BarContext = (): Chart => {
  const { setup, design, common, updateDesign, updateSetup, updateCommon, setCodeFragments } = useChart<
    BarSetup,
    IBarDesign
  >(PlotType.bar);

  useEffect(() => {
    (async () => {
      const cb = await buildCode(common, [...barChart.baseAttrs, ...barChart.designAttrs]);
      setCodeFragments(cb);
    })();
  }, [setup, design, common]);

  useEffect(() => {
    (async () => {
      await fetchHeaders(common.inputData, common.hasHeaders, updateSetup);
    })();
  }, [common.hasHeaders, common.inputData]);

  const orientation = DropdownAttr({
    value: setup.orientation,
    onChange: (_, data) => updateSetup({ orientation: data.optionValue }),
    label: "Orientation",
    options: ["Horizontal", "Vertical", "None"],
    codeKey: "orient",
    codeValueMap: Orientation,
    dataTestID: "orientation",
    codeRequiresInteraction: true,
  });

  const errorBar = DropdownAttr({
    value: setup.errorBar,
    label: "Error Bar",
    dataTestID: "error-bar",
    placeholder: "--None--",
    onChange: (_, data) => updateSetup({ errorBar: data.optionValue }, "--None--"),
    options: IntervalOptions,
  });

  const estimator = DropdownAttr({
    value: setup.estimator,
    onChange: (_, data) => updateSetup({ estimator: data.optionValue }, "--None--"),
    label: "Estimator",
    options: ["--None--", "Mean", "Median", "Max", "Min"],
    placeholder: "--None--",
    codeKey: "estimator",
    codeValueMap: Estimators,
  });

  // Two intervals are used because they have different max values and initial setpoints
  // based on the error bar chosen. Only one will be shown at a time because the
  // visibleDependencies are mutually exclusive.
  const primaryInterval = SpinnerAttr({
    label: "Interval",
    value: setup.primaryInterval,
    step: 1,
    max: 100,
    suffix: "%",
    onChange: (_, data) => updateSetup({ primaryInterval: parseInt(data) }),
    visibleDependencies: [dependencyInValues(errorBar, ["CI", "PI", "None"])],
    enabledDependencies: [dependencyInValues(errorBar, ["CI", "PI"])],
    dataTestID: "interval",
  });
  primaryInterval.getCode = (code: CodeBuilder) => {
    code.plotAttrs.push(`errorbar=('${setup.errorBar.toLowerCase()}', ${setup.primaryInterval})`);
  };

  const secondaryInterval = SpinnerAttr({
    label: "Interval",
    value: setup.secondInterval,
    step: 1,
    max: Infinity,
    suffix: "%",
    onChange: (_, data) => updateSetup({ secondInterval: parseInt(data) }),
    visibleDependencies: [dependencyInValues(errorBar, ["SE", "SD"])],
    enabledDependencies: [dependencyInValues(errorBar, ["SE", "SD"])],
  });
  secondaryInterval.getCode = (code: CodeBuilder) => {
    code.plotAttrs.push(`errorbar=('${setup.errorBar.toLowerCase()}', ${setup.secondInterval})`);
  };

  const Legend = LegendAttr({
    value: design.legendPosition,
    label: "Legend Position",
    onChange: (_, data) => updateDesign({ legendPosition: data.optionValue }, "--Select--"),
    callKey: "move_legend",
    codeValueMap: LegendPosition,
    topValue: design.topPosition,
    rightValue: design.rightPosition,
    codeRequiresInteraction: true,
  });

  const colorBy = AxisDropdownAttr({
    value: setup.colorBy,
    onChange: (_, data) => updateSetup({ colorBy: data.optionValue }, "--None--"),
    label: "Color By",
    options: ["--None--", ...setup.headers],
    placeholder: "--None--",
    codeKey: "hue",
    hasHeaders: common.hasHeaders,
  });

  const palette = PaletteAttr({
    value: design.palette,
    onChange: (_, data) => {
      updateDesign({ palette: data.optionText });
    },
    codeKey: "palette",
    placeholder: "Accent",
    visibleDependencies: [dependencyNotEqualsValue(colorBy, "")],
    codeRequiresInteraction: true,
    dataTestID: "palette",
  });

  const barChart: Chart = {
    baseAttrs: [
      DataRangeAttr({
        inputData: common.inputData,
        onChangeSelection: (newSelection: RangeSelection) => updateCommon({ inputData: newSelection }),
      }),
      CheckBoxAttr({
        label: "Has headers",
        value: common.hasHeaders,
        onChange: (_, e) => updateCommon({ hasHeaders: e.checked }),
        dataTestID: "headers",
      }),
      OutputAttr({
        outputCell: common.outputCell,
        onChange: (newSelection: RangeSelection) => updateCommon({ outputCell: newSelection }),
      }),
      orientation,
      DividerAttr(),
      HeadingAttr({ title: "Data", tooltip: "Select data cells and parameters" }),
      AxisDropdownAttr({
        value: setup.xAxis,
        onChange: (_, data) => {
          updateSetup({ xAxis: data.optionValue }, "--Select--");
          updateDesign({ xAxisLabel: data.optionValue }, "--Select--");
        },
        dataTestID: "x-axis",
        label: "X-Axis",
        options: ["--Select--", ...setup.headers],
        codeKey: "x",
        hasHeaders: common.hasHeaders,
      }),
      AxisDropdownAttr({
        value: setup.yAxis,
        onChange: (_, data) => {
          updateSetup({ yAxis: data.optionValue }, "--Select--");
          updateDesign({ yAxisLabel: data.optionValue }, "--Select--");
        },
        dataTestID: "y-axis",
        label: "Y-Axis",
        options: ["--Select--", ...setup.headers],
        codeKey: "y",
        hasHeaders: common.hasHeaders,
      }),
      colorBy,
      DividerAttr(),
      CollapsibleAttr({
        collapsed: setup.isCollapsed,
        label: "Error",
        tooltip: "Measure error in data",
        toggle: () => {
          updateSetup({ isCollapsed: !setup.isCollapsed });
        },
        children: [
          estimator,
          errorBar,
          primaryInterval,
          secondaryInterval,
          SpinnerAttr({
            label: "Bootstrap Samples",
            value: setup.bootstrapSamples,
            step: 1,
            onChange: (_, data) => updateSetup({ bootstrapSamples: data }),
            codeKey: "n_boot",
            dataTestID: "bootstrap",
            codeRequiresInteraction: true,
            enabledDependencies: [dependencyNotEqualsValue(errorBar, "--None--")],
          }),
          SpinnerAttr({
            label: "Seed",
            value: setup.seed,
            step: 1,
            max: 2 ** 32 - 1,
            onChange: (_, data) => updateSetup({ seed: data }),
            codeKey: "seed",
            codeRequiresInteraction: true,
            dataTestID: "seed",
            enabledDependencies: [dependencyNotEqualsValue(errorBar, "--None--")],
          }),
        ],
      }),
      DividerAttr(),
      HeadingAttr({
        title: "Axes",
        tooltip: "Set scale for axes",
      }),
      DropdownAttr({
        value: setup.xAxisScale,
        onChange: (_, data) => updateSetup({ xAxisScale: data.optionValue }),
        label: "X-Axis Scale",
        options: ["Linear", "Log"],
        placeholder: "Linear",
        callKey: "xscale",
        codeValueMap: Axes,
        visibleDependencies: [dependencyEqualsValue(orientation, "Vertical")],
      }),
      DropdownAttr({
        value: setup.yAxisScale,
        onChange: (_, data) => updateSetup({ yAxisScale: data.optionValue }),
        label: "Y-Axis Scale",
        options: ["Linear", "Log"],
        placeholder: "Linear",
        callKey: "yscale",
        codeValueMap: Axes,
        visibleDependencies: [dependencyEqualsValue(orientation, "Horizontal")],
      }),
    ],
    designAttrs: [
      LabelAttr({
        value: design.plotTitle,
        placeholder: "Title",
        label: "Title",
        codeKey: "title",
        onChange: (event) => updateDesign({ plotTitle: event.currentTarget.value }),
      }),
      LabelAttr({
        value: design.xAxisLabel,
        placeholder: "Defaults header",
        label: "X-Axis Label",
        codeKey: "xlabel",
        dataTestID: "x-axis-label",
        onChange: (event) => updateDesign({ xAxisLabel: event.currentTarget.value }),
      }),
      LabelAttr({
        value: design.yAxisLabel,
        placeholder: "Defaults header",
        label: "Y-Axis Label",
        codeKey: "ylabel",
        dataTestID: "y-axis-label",
        onChange: (event) => updateDesign({ yAxisLabel: event.currentTarget.value }),
      }),
      DividerAttr(),
      CollapsibleAttr({
        collapsed: design.borderCollapsed,
        label: "Border",
        toggle: () => {
          updateDesign({ borderCollapsed: !design.borderCollapsed });
        },
        children: [
          SingleChartBorders({
            label: "Top",
            value: design.topSpine,
            onChange: (_, e) => updateDesign({ topSpine: e.checked }),
            callKey: "top",
          }),
          SingleChartBorders({
            label: "Right",
            value: design.rightSpine,
            onChange: (_, e) => updateDesign({ rightSpine: e.checked }),
            callKey: "right",
          }),
          SingleChartBorders({
            label: "Bottom",
            value: design.bottomSpine,
            onChange: (_, e) => updateDesign({ bottomSpine: e.checked }),
            callKey: "bottom",
          }),
          SingleChartBorders({
            label: "Left",
            value: design.leftSpine,
            onChange: (_, e) => updateDesign({ leftSpine: e.checked }),
            callKey: "left",
          }),
        ],
      }),
      DividerAttr(),
      CollapsibleAttr({
        collapsed: design.gridlinesCollapsed,
        label: "Gridlines",
        toggle: () => {
          updateDesign({ gridlinesCollapsed: !design.gridlinesCollapsed });
        },
        children: [
          GridlinesAttr({
            majorHorizontal: design.majorHorizontal,
            majorVertical: design.majorVertical,
            minorHorizontal: design.minorHorizontal,
            minorVertical: design.minorVertical,
            onChange: (key, event) => {
              updateDesign({ [key]: event.checked });
            },
          }),
        ],
      }),
      DividerAttr(),
      HeadingAttr({ title: "Axes Label Rotation" }),
      SpinnerAttr({
        label: "X-Ticks",
        value: design.xticks,
        step: 5,
        max: 180,
        min: -180,
        onChange: (_, data) => updateDesign({ xticks: parseInt(data) }),
        callKey: "xticks",
        suffix: "°",
        codeRequiresInteraction: true,
      }),
      SpinnerAttr({
        label: "Y-Ticks",
        value: design.yticks,
        step: 5,
        max: 180,
        min: -180,
        onChange: (_, data) => updateDesign({ yticks: parseInt(data) }),
        callKey: "yticks",
        suffix: "°",
        codeRequiresInteraction: true,
      }),
      DividerAttr(),
      CheckBoxAttr({
        value: design.fill,
        onChange: (_, e) => updateDesign({ fill: e.checked }),
        label: "Fill",
        codeKey: "fill",
        codeRequiresInteraction: true,
      }),
      ColorPickerAttr({
        value: design.color,
        label: "Color",
        onChange: (hexColor) => updateDesign({ color: `#${hexColor}` }),
        codeKey: "color",
        codeRequiresInteraction: true,
      }),
      palette,
      DividerAttr(),
      HeadingAttr({ title: "Bar Formatting" }),
      SpinnerAttr({
        label: "Width",
        value: design.width,
        step: 0.1,
        max: 2 ** 32 - 1,
        onChange: (_, data) => updateDesign({ width: data }),
        codeKey: "width",
        codeRequiresInteraction: true,
      }),
      CheckBoxAttr({
        label: "Dodge",
        value: design.dodge,
        onChange: (_, e) => updateDesign({ dodge: e.checked }),
        codeKey: "dodge",
        codeRequiresInteraction: true,
        dataTestID: "dodge",
      }),
      DividerAttr(),
      HeadingAttr({ title: "Legend" }),
      // LegendStyleAttr,
      Legend,
      DividerAttr(),
      CollapsibleAttr({
        collapsed: design.isCollapsed,
        label: "Error",
        toggle: () => {
          updateDesign({ isCollapsed: !design.isCollapsed });
        },
        children: [
          ErrorKws({
            value: design.errorColor,
            spinnerValue: design.errorBarWidth,
            onValueChange: (_, data) => updateDesign({ errorBarWidth: parseInt(data) }),
            onChange: (color) => updateDesign({ errorColor: `#${color}` }),
            colorValue: design.errorColor,
            enabledDependencies: [dependencyNotEqualsValue(errorBar, "--None--")],
            spinnerReqiuiresInteraction: true,
            colorRequiresInteraction: true,
          }),
          SpinnerAttr({
            label: "Error Bar Cap Width",
            value: design.errorBarCapWidth,
            step: 0.1,
            max: 1,
            onChange: (_, data) => updateDesign({ errorBarCapWidth: data }),
            codeKey: "capsize",
            dataTestID: "capsize",
            codeRequiresInteraction: true,
            enabledDependencies: [dependencyNotEqualsValue(errorBar, "--None--")],
          }),
          DividerAttr(),
          HeadingAttr({
            title: "Legend",
            tooltip:
              "Use the Top and Right fields for more fine-grained control, including moving the legend outside of the axes.",
          }),
          SpinnerAttr({
            label: "Top",
            value: design.topPosition,
            onChange: (_, data) => updateDesign({ topPosition: data }),
            step: 0.1,
            max: 2,
            codeRequiresInteraction: true,
            enabledDependencies: [dependencyNotEqualsValue(Legend, "--Select--")],
          }),
          SpinnerAttr({
            label: "Right",
            value: design.rightPosition,
            step: 0.1,
            max: 2,
            onChange: (_, data) => updateDesign({ rightPosition: data }),
            codeRequiresInteraction: true,
            enabledDependencies: [dependencyNotEqualsValue(Legend, "--Select--")],
          }),
        ],
      }),
    ],
  };
  return barChart;
};

const BarForm = () => {
  const barChart = BarContext();
  return buildReactFromAttrs(barChart.baseAttrs, 130);
};

export const BarDesign = () => {
  const barDesign = BarContext();
  return buildReactFromAttrs(barDesign.designAttrs, 140);
};

export default BarForm;
