import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Field,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
  Text,
} from "@fluentui/react-components";
import { Add16Filled, CopyRegular, DeleteRegular } from "@fluentui/react-icons";
import { Row } from "../components/Layout/Space";
import PlotService, { VizDef, VizDefIdentifier } from "../../data/plot-api";
import { plotsLookup } from "../../data/plot-types";
import { useChartManager } from "../hooks/plots/useCentralViz";
import { Mode } from "../types";
import styles from "../styles.module.scss";
import { PopoverMenuButton } from "../components/PopoverMenuButton";

const COLUMNS = [
  { columnKey: "plotType", label: "Chart Type", width: 110 },
  { columnKey: "sheetName", label: "Sheet", width: null },
  { columnKey: "addr", label: "Cell", width: 60 },
  { columnKey: "actions", label: "", width: 32 },
  //   { columnKey: "lastModified", label: "Last Modified" },
];

export type ChartTableRow = VizDefIdentifier;

function Visualizations() {
  const navigate = useNavigate();

  const { plotType, tab, mode, setPlotType, setTab, setMode, hydrateContext } = useChartManager();

  useEffect(() => {
    if (plotType && tab && mode) {
      navigate("/viz/edit");
    }
  }, [plotType, tab, mode]);

  async function hydrateSetup(item: VizDefIdentifier) {
    const def: VizDef = await PlotService.getVizDef(item.bindId);
    hydrateContext(def, item.formula);
    setPlotType(item.plotType);
    setMode(Mode.Edit);
    setTab(def.common.typedCode ? "code" : "setup");
  }

  async function hydrateSetupNoOutput(item: VizDefIdentifier) {
    const def: VizDef = await PlotService.getVizDef(item.bindId);
    def.common.outputCell = {};
    hydrateContext(def, item.formula);
    setPlotType(item.plotType);
    setMode(Mode.New);
    setTab(def.common.typedCode ? "code" : "setup");
  }

  const [vizDefinitions, setVizDefinitions] = useState<VizDefIdentifier[]>([]);
  async function loadVizDefinitions() {
    const defs: VizDefIdentifier[] = await PlotService.listVizDefs();
    // PlotService.listVizDefs returns a new object reference every time
    setVizDefinitions(defs);
  }

  useEffect(() => {
    (async () => {
      await loadVizDefinitions();
    })();
  }, []);

  const [error, setError] = useState<string>("");
  async function deleteItem(item: VizDefIdentifier) {
    try {
      await PlotService.deleteVizDef(item.bindId);
      await loadVizDefinitions();
    } catch (e) {
      console.error(e);
      setError("An error occurred when deleting your visualization. Please try again.");
    }
  }

  function getCellContent(item: VizDefIdentifier, key: string) {
    const text = item[key];
    if (key === "plotType") {
      return (
        <>
          <img
            style={{ height: 20, width: 20, verticalAlign: "bottom", marginRight: 5 }}
            src={plotsLookup[text].imgIcon}
          />
          <Text>{plotsLookup[text].name}</Text>
        </>
      );
    } else {
      return text;
    }
  }

  return (
    <>
      {!plotType && (
        <div>
          <Row justifyContent="flex-end">
            <Button
              style={{ color: styles.baseText }}
              appearance="transparent"
              className="secondary"
              icon={<Add16Filled />}
              iconPosition="before"
              onClick={() => navigate("/viz/create")}
              data-testid="new-chart"
            >
              New Chart
            </Button>
          </Row>
          <div style={{ marginTop: 15 }}>
            <Field validationMessage={error} />
            <Table aria-label="Data table" style={{ borderBottom: `1px solid ${styles.gray200}` }}>
              <TableHeader>
                <TableRow>
                  {COLUMNS.map((column) => {
                    return (
                      <TableHeaderCell
                        key={column.columnKey}
                        style={{ fontWeight: "bold", color: styles.gray800, width: column.width }}
                      >
                        {column.label}
                      </TableHeaderCell>
                    );
                  })}
                </TableRow>
              </TableHeader>
              <TableBody>
                {vizDefinitions?.length === 0 ? (
                  <TableRow>
                    <TableCell>No results</TableCell>
                    {new Array(COLUMNS.length - 1).fill(0).map((value, index) => (
                      <TableCell key={`${value}-${index}`}></TableCell>
                    ))}
                  </TableRow>
                ) : (
                  vizDefinitions.map((item) => (
                    <TableRow key={item.bindId} style={{ border: 0 }}>
                      {COLUMNS.map((column) => {
                        if (column.columnKey !== "actions" && item[column.columnKey]) {
                          return (
                            <TableCell
                              key={column.columnKey}
                              style={{
                                height: 33,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                cursor: "pointer",
                              }}
                              onClick={() => hydrateSetup(item)}
                            >
                              {getCellContent(item, column.columnKey)}
                            </TableCell>
                          );
                        }
                      })}
                      <TableCell>
                        <PopoverMenuButton
                          kind="definition"
                          id={item.bindId}
                          actions={[
                            {
                              actionName: "Duplicate",
                              onClickAction: async () => await hydrateSetupNoOutput(item),
                              icon: <CopyRegular />,
                            },
                            {
                              actionName: "Delete",
                              onClickAction: async () => await deleteItem(item),
                              icon: <DeleteRegular />,
                            },
                          ]}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </div>
        </div>
      )}
    </>
  );
}

export default Visualizations;
