import React from "react";
import { Field } from "@fluentui/react-components";
import { TableEdit20Regular } from "@fluentui/react-icons";
import { RangeType, RangeSelection, refreshRangeSelection } from "xlcommon/src/excel/excel-grid-utils";
import "./AddressChooser.scss";

export interface AddressChooserProperties {
  selection: RangeSelection;
  onSelection: (newSelection: RangeSelection) => void;
  placeholder?: string; // text to display if the address is blank
  label?: string;
  size?: "small" | "large";
  width?: number | string;
}

export default function AddressChooser({
  selection,
  onSelection,
  placeholder = "Click to select",
  label = "Placement in Workbook",
  size = "large",
}: AddressChooserProperties) {
  // Ensure Bindings have up-to-date fullAddress
  React.useEffect(() => {
    (async () => {
      if (selection?.rangeType !== RangeType.CellBinding || selection?.identifier) {
        onSelection(
          await refreshRangeSelection({
            ...selection,
            rangeType: RangeType.CellBinding, // force correct range type
          })
        );
      }
    })();
  }, []);

  async function openPrompt() {
    let options = { promptText: "Select an address" };
    if (selection?.identifier) {
      options["id"] = selection.identifier;
    }
    Office.context.document.bindings.addFromPromptAsync(Office.BindingType.Matrix, options, promptCallback);
  }

  async function promptCallback(result) {
    if (result.value === undefined) {
      console.log("User cancelled the dialog");
      return;
    }
    onSelection(
      await refreshRangeSelection({
        ...selection,
        rangeType: RangeType.CellBinding,
        identifier: result.value.id,
      })
    );
  }

  if (size === "small") {
    return (
      <div className="address-chooser" onClick={openPrompt}>
        <div className="field-content">
          <div className="field-content location">
            <TableEdit20Regular />
            <span style={{ opacity: location ? 1 : 0.7 }}>
              {selection?.displayName ? selection?.displayName : placeholder}
            </span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="address-chooser" onClick={openPrompt}>
      <Field
        data-testid="address-chooser"
        style={{ fontWeight: 200 }}
        label={label}
        size="small"
        orientation="vertical"
      >
        <div className="field-content">
          <div className="field-content location">
            <TableEdit20Regular />
            <span style={{ opacity: location ? 1 : 0.7 }}>
              {selection?.displayName ? selection?.displayName : placeholder}
            </span>
          </div>
        </div>
      </Field>
    </div>
  );
}
