import { BaseDesign, BaseSetup } from "./PlotTypes";
import { ValueTracker } from "../../pages/Visualization/MVCShared/types";
import styles from "../../styles.module.scss";

export interface ViolinSetup extends BaseSetup {
  // TODO: specifics
  bandwidth: ValueTracker<string>;
  custom: ValueTracker<number>;
  cut: ValueTracker<number>;
  gridSize: ValueTracker<number>;
  densityNorm: ValueTracker<string>;
  commonNorm: ValueTracker<boolean>;
  isCollapsed: boolean;
}

export interface ViolinDesign extends BaseDesign {
  // TODO: specifics
  width: ValueTracker<number>;
  saturation: ValueTracker<number>;
  lineWidth: ValueTracker<number>;
  split: ValueTracker<boolean>;
  dodge: ValueTracker<boolean>;
  violinInner: ValueTracker<string>;
  legendPosition: string;
  topPosition: ValueTracker<number>;
  rightPosition: ValueTracker<number>;
  yticks: ValueTracker<number>;
  xticks: ValueTracker<number>;
  isCollapsed: boolean;
}

export const violinSetup: ViolinSetup = {
  headers: [],
  xAxis: "",
  yAxis: "",
  colorBy: "",
  orientation: new ValueTracker("Vertical"),
  bandwidth: new ValueTracker("Scott"),
  custom: new ValueTracker(0),
  cut: new ValueTracker(2),
  gridSize: new ValueTracker(100),
  densityNorm: new ValueTracker("Area"),
  commonNorm: new ValueTracker(false),
  isCollapsed: true,
  // if color by scale color should be true
};

export const violinDesign: ViolinDesign = {
  plotTitle: "",
  xAxisLabel: "",
  yAxisLabel: "",
  fill: new ValueTracker(true),
  color: new ValueTracker(styles.seabornDefault),
  palette: new ValueTracker("Tab10"),
  saturation: new ValueTracker(0.75),
  lineWidth: new ValueTracker(0),
  width: new ValueTracker(0.8),
  split: new ValueTracker(false),
  dodge: new ValueTracker(true),
  // true by default unless color by is selected otherwise set to false
  violinInner: new ValueTracker("Box"),
  legendPosition: "--Select--",
  topPosition: new ValueTracker(0.5),
  rightPosition: new ValueTracker(1),
  yticks: new ValueTracker(0),
  xticks: new ValueTracker(0),
  isCollapsed: true,
  majorHorizontal: new ValueTracker(false),
  majorVertical: new ValueTracker(false),
  minorHorizontal: new ValueTracker(false),
  minorVertical: new ValueTracker(false),
  leftSpine: true,
  bottomSpine: true,
  topSpine: false,
  rightSpine: false,
  borderCollapsed: true,
  gridlinesCollapsed: true,
};
