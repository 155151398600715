import { BaseDesign, BaseSetup } from "./PlotTypes";
import { ValueTracker } from "../../pages/Visualization/MVCShared/types";

export interface ScatterSetup extends BaseSetup {
  // TODO: specifics
  sizeBy: string;
  styleBy: string;
  newXAxisScale: ValueTracker<string>;
  newYAxisScale: ValueTracker<string>;
  isCollapsed: boolean;
}

export interface ScatterDesign extends BaseDesign {
  // TODO: specifics
  marker: ValueTracker<string>;
  legendStyle: ValueTracker<string>;
  legendPosition: string;
  topPosition: ValueTracker<number>;
  rightPosition: ValueTracker<number>;
  yticks: ValueTracker<number>;
  xticks: ValueTracker<number>;
  isCollapsed: boolean;
}

export const scatterSetup: ScatterSetup = {
  headers: [],
  xAxis: "",
  yAxis: "",
  colorBy: "",
  sizeBy: "",
  styleBy: "",
  newXAxisScale: new ValueTracker("Linear"),
  newYAxisScale: new ValueTracker("Linear"),
  isCollapsed: true,
};

export const scatterDesign: ScatterDesign = {
  plotTitle: "",
  xAxisLabel: "",
  yAxisLabel: "",
  marker: new ValueTracker("Point"),
  legendStyle: new ValueTracker("Auto"),
  palette: new ValueTracker("Tab10"),
  legendPosition: "--Select--",
  topPosition: new ValueTracker(0.5),
  rightPosition: new ValueTracker(1),
  yticks: new ValueTracker(0),
  xticks: new ValueTracker(0),
  isCollapsed: true,
  majorHorizontal: new ValueTracker(false),
  majorVertical: new ValueTracker(false),
  minorHorizontal: new ValueTracker(false),
  minorVertical: new ValueTracker(false),
  leftSpine: true,
  bottomSpine: true,
  topSpine: false,
  rightSpine: false,
  borderCollapsed: true,
  gridlinesCollapsed: true,
};
