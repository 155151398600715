import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PlotCard } from "./Visualization/PlotCard";
import { plotsList, PlotType } from "../../data/plot-types";
import { snakeEyesRecord } from "../../analytics/snake-eyes";
import { useChartManager } from "../hooks/plots/useCentralViz";
import { Mode } from "../types";
import "./Visualization/Viz.scss";

function CreateVisualization() {
  const navigate = useNavigate();

  const { plotType, tab, mode, setPlotType, setTab, setMode, resetContext } = useChartManager();

  useEffect(() => {
    if (plotType && tab && mode) {
      navigate("/viz/edit");
    }
  }, [plotType, tab, mode]);

  function handlePlotClick(plotType: PlotType) {
    resetContext();
    setPlotType(plotType);
    setTab("setup");
    setMode(Mode.New);
    snakeEyesRecord({
      event: "viz/chart_selected",
      eventParams: { chart: plotType },
    });
  }

  return (
    <div>
      <h2 style={{ fontWeight: 600, fontSize: 19, margin: "8px 0" }}>Create Chart</h2>
      <p style={{ marginTop: 0 }}>Choose one of the following plot types to visualize the data in your sheet</p>
      {plotsList.map((plot, index) => (
        <PlotCard
          key={index}
          type={`${plot.name} Chart`}
          imageSrc={plot.imgIcon}
          tooltipInfo={plot.tooltipInfo}
          img={plot.img}
          onCardClick={() => handlePlotClick(plot.plotType)}
          description={plot.description}
          dataTestID={plot.plotType}
        />
      ))}
    </div>
  );
}

export default CreateVisualization;
