import { BaseDesign, BaseSetup } from "./PlotTypes";
import { ValueTracker } from "../../pages/Visualization/MVCShared/types";
import styles from "../../styles.module.scss";

export interface BoxSetup extends BaseSetup {
  // TODO: specifics
  orientation: ValueTracker<string>;
  whiskersRange: ValueTracker<number>;
}

export interface BoxDesign extends BaseDesign {
  // TODO: specifics
  marker: ValueTracker<string>; // This should show the options for the marker "some1" | "some2"
  markerSize: ValueTracker<number>;
  width: ValueTracker<number>;
  saturation: ValueTracker<number>;
  lineWidth: ValueTracker<number>;
  colorOrder?: string;
  dodge: ValueTracker<boolean>;
  legendPosition: string;
  topPosition: ValueTracker<number>;
  rightPosition: ValueTracker<number>;
  yticks: ValueTracker<number>;
  xticks: ValueTracker<number>;
  isCollapsed: boolean;
}

export const boxSetup: BoxSetup = {
  orientation: new ValueTracker("Vertical"),
  headers: [],
  xAxis: "",
  yAxis: "",
  colorBy: "",
  whiskersRange: new ValueTracker(1.5),
};

export const boxDesign: BoxDesign = {
  plotTitle: "",
  xAxisLabel: "",
  yAxisLabel: "",
  color: new ValueTracker(styles.seabornDefault),
  fill: new ValueTracker(true),
  palette: new ValueTracker("Accent"),
  marker: new ValueTracker("Point"),
  width: new ValueTracker(0.8),
  saturation: new ValueTracker(0.75),
  lineWidth: new ValueTracker(0),
  markerSize: new ValueTracker(5),
  dodge: new ValueTracker(true),
  colorOrder: "",
  legendPosition: "--Select--",
  topPosition: new ValueTracker(0.5),
  rightPosition: new ValueTracker(1),
  yticks: new ValueTracker(0),
  xticks: new ValueTracker(0),
  isCollapsed: true,
  majorHorizontal: new ValueTracker(false),
  majorVertical: new ValueTracker(false),
  minorHorizontal: new ValueTracker(false),
  minorVertical: new ValueTracker(false),
  leftSpine: true,
  bottomSpine: true,
  topSpine: false,
  rightSpine: false,
  borderCollapsed: true,
  gridlinesCollapsed: true,
};
